/**
* @copyright: Ax Marketing 2021
* @author: Cristian Cedeño<ccedeno@inmov.com>
* @date: 2021-12-20 14:22:23-0500 
**/
import * as React from 'react';
import {Select} from '../../../../_metronic/_partials/controls';
import {useGetChannelsQuery} from '../../../services/miqApi';
import _ from 'lodash';
import {FieldProps} from 'formik';

export interface StoreChanelSelectProps {
country: string;
name: string;
}

const StoreChanelSelect: React.FunctionComponent<StoreChanelSelectProps> = (props: StoreChanelSelectProps) => {
   const { data: res = {}, isLoading: loading, error} = useGetChannelsQuery(props.country) 
  const {data: cities = []} = res;

  const placeholder = _.cond([
    [_.constant(loading), _.constant('Cargando')],
    [_.constant(!!error), _.constant('Error conectando con el servidor')],
    [_.constant(!props.country), _.constant('Selecciona un país')],
    [_.stubTrue, _.constant('Selecciona')],
  ])(null)

  const options = cities.map((c: any) => ({
    label: c.descripcion,
    value: c.id
  }))
  return (
    <Select
      isMulti
      isDisabled={!props.country}
      placeholder={placeholder}
      name={props.name}
      options={options}
    />
  );
}

export default StoreChanelSelect;
