import * as React from 'react';
import { Box, Tooltip } from '@material-ui/core';

export interface EventRenderProps {
  eventContent: any,
  history?: any
}

const EventRender: React.SFC<EventRenderProps> = (props: EventRenderProps) => {
  const { eventContent } = props;
  return (
    <Tooltip title={eventContent.event.extendedProps.message} placement="top" arrow>
      <Box
        className="eventPicker"
        boxShadow={3}
      >
        <span className="state" style={{ backgroundColor: eventContent.event.extendedProps.state ? '#3da0ff' : '#eeeff5' }} />
        {eventContent.event.title}
      </Box>
    </Tooltip>
  );
}

export default EventRender;