import * as React from 'react';
import BaseBadge from './BaseBadge';

export interface InfoBadgeProps {
  children: React.ReactNode
}
 
const InfoBadge: React.SFC<InfoBadgeProps> = (props: InfoBadgeProps) => {
  return (
    <BaseBadge {...props} background="#BFF0FB" color="#03738B" />
  );
}
 
export default InfoBadge;