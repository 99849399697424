import * as React from 'react';
import useFetch from 'use-http';
import RadioInput from '../ui/RadioInput';

export interface NotificationsTypeRadioProps {
  
}
 
const NotificationsTypeRadio: React.SFC<NotificationsTypeRadioProps> = (props: NotificationsTypeRadioProps) => {
  const {data = {}} = useFetch(`${process.env.REACT_APP_API_HOST}/api/types/notification/types/list`, {}, []);
  const {data: types = []} = data;
  const typesOptions = types.map((t: any) => ({
    label: t.name,
    value: t.id
  }));
  return (
      <RadioInput name="recipients_type_id" options={typesOptions}></RadioInput>
  )
}
 
export default NotificationsTypeRadio;