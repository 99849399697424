import * as React from 'react';
import { Pagination } from 'react-bootstrap';

export interface PaginationProps {

}

const PaginationTables: React.SFC<PaginationProps> = (props: PaginationProps) => {
    return (
        <Pagination>
            <Pagination.First />
                <Pagination.Item active>{1}</Pagination.Item>
            <Pagination.Last />
        </Pagination>
    );
}

export default PaginationTables;