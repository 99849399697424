import * as React from 'react';
import Lottie from 'react-lottie';
import animationData from './loading.json';

const defaultOptions = {
  loop: true,
  autoplay: true, 
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const Loading = () => {
    return (
      <Lottie
        options={defaultOptions}
        height={100}
        width={100}
      />
    )
};

export default Loading;
