import * as React from 'react';
import ProgressBarBase, { ProgressBarBaseProps } from './ProgressBarBase';

export interface InfoProgressBarProps extends Omit<ProgressBarBaseProps, 'color'> {
  
}
 
const InfoProgressBar: React.SFC<InfoProgressBarProps> = (props: InfoProgressBarProps) => {
  return (
    <ProgressBarBase
      {...props}
      color="#1099BB"
    />
  );
}
 
export default InfoProgressBar;