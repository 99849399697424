import * as React from 'react';
import CounterCard from './CounterCard';

export interface RetosCounterProps {
  total: any
}
 
const RetosCounter: React.SFC<RetosCounterProps> = (props: RetosCounterProps) => {
  return (
    <CounterCard color="#FDC211" count={props.total} label="Retos" logo="/img/challenge.svg"></CounterCard>
  );
}
 
export default RetosCounter;