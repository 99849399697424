import * as React from 'react';
import useFetch from 'use-http';
import _ from 'lodash';
import CounterCard from './CounterCard';

export interface PdvsImpactedProps {
  
}
 
const PdvsImpacted: React.SFC<PdvsImpactedProps> = (props: PdvsImpactedProps) => {
  const {data} = useFetch(`${process.env.REACT_APP_API_HOST}/api/kpi/comercial/actions`, {}, []);

  const pdv = _.get(data, 'data.pdv', 0);
  
  
  return (
    <CounterCard color="white" count={pdv} customColor="#BB2D11" label="PDV Impactados" logo="/img/store.svg"></CounterCard>
  );
}
 
export default PdvsImpacted;