import * as React from 'react';
import { Table } from 'react-bootstrap';
import _ from 'lodash';
import { orderIdNulleable, useOrderList } from '../hooks/useOrderList';
import { PromotionOrder } from '../entities/OrderListResponse';
import { ExportExcelButton } from '../../Tables/ExportExcelButton';

export interface OrderTableProps {
  orderId: orderIdNulleable,
}

const OrderTable: React.SFC<OrderTableProps> = ({orderId}: OrderTableProps) => {
  const {response, loading, error} = useOrderList(orderId);
  const orders: PromotionOrder[] = _.get(response, 'data.data.0.promotion_orders', []);
  const promoName: string = _.get(response, 'data.data.0.name', '');
  
  if (!orderId) {
    return null;
  }

  return (
    <div className="mt-5">
      <ExportExcelButton csvData={orders.map(item => ({
        promocion: promoName,
        establecimiento: item.pdv.store_name,
        direccion: item.delivery_address,
        codigo: item.code,
        cantidad: item.quantity,
        documento_pdv: item.pdv.document,
        telefono: item.pdv.phone,
      }))} fileName="promo"></ExportExcelButton>
      <Table>
        <thead>
          <tr>
            <th>Establecimiento</th>
            <th>Dirección del PDV</th>
            <th>Nit o Documento pdv</th>
            <th>Teléfono</th>
            <th>Código</th>
            <th>Cantidad</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan={4}>
                Cargando...
              </td>
            </tr>
          )}
          {error && (
            <tr>
              <td colSpan={4}>
                Error
              </td>
            </tr>
          )}
          {!loading && !error && orders.length === 0 && (
            <tr>
              <td colSpan={4}>
                No existen pedidos para esta promoción
              </td>
            </tr>
          )}
          {orders.map(item => (
            <tr>
              <td>{item.pdv.store_name}</td>
              <td>{item.delivery_address}</td>
              <td>{item.pdv.document}</td>
              <td>{item.pdv.phone}</td>
              <td>{item.code}</td>
              <td>{item.quantity}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
 
export default OrderTable;