export {default as mockAxios} from "./__mocks__/mockAxios";
export {default as setupAxios} from "./setupAxios";

export function getGenericHeaders() {
    return {
        Accept: 'application/json',
        'X-Language': 'ES',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }
}
