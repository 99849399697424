import { Divider } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { Alert, Button } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Card, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import useFetch from "use-http";
import get from 'lodash/get';
import { getGenericHeaders } from "../../redux";
import DbSelect from "../modules/SetupDb/components/DbSelect";
import {GET_CURRENT_DB, updateDb} from '../modules/SetupDb/_redux/setupDbCrud'

export function SetupPage() {
  const { data = {}, loading, error } = useFetch(`${GET_CURRENT_DB}`, {
      headers: getGenericHeaders()
  }, [])



    return (
        <div className="row">
            <div className="col-12">
                {error && <Alert variant="danger">Error cargando la información</Alert>}
              {loading ? <div>Cargando...</div> : <Formik
                initialValues={{
                  dbName: get(data, 'data.database_type_id', null),
                }}
                onSubmit={async (values) => {
                    try {
                        await updateDb(values.dbName);
                        Swal.fire({
                            icon: 'success',
                            text: 'Se ha actualizado correctamente tu selección'
                        })
                    } catch(ex) {
                        Swal.fire({
                            icon: 'error',
                            text: 'Error conectando con el servidor'
                        })
                    }
                }}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <Card>
                      <Card.Header>
                          <Card.Title>SetUp</Card.Title>
                      </Card.Header>
                      <Card.Body>
                          <div className="row">
                              <Form.Group as={Col} md="4" controlId="formBasicEmail">
                                  <Form.Label>Base de Datos de Origen</Form.Label>
                                  <DbSelect name="dbName" disabled={loading} />
                                  <Form.Text className="text-muted">
                                      Ingrese el dato
                                  </Form.Text>
                              </Form.Group>
                              {/* <Form.Group as={Col} md="4" controlId="formBasicEmail">
                                  <Form.Label>Cartografía</Form.Label>
                                  <Form.Control as="select">
                                      <option>Seleccione</option>
                                      <option>Propia</option>
                                  </Form.Control>
                                  <Form.Text className="text-muted">
                                      Ingrese el dato
                                  </Form.Text>
                              </Form.Group>
                              <Form.Group as={Col} md="4" controlId="formBasicEmail">
                                  <Form.Label>Cruce de Bases de Datos</Form.Label>
                                  <Form.Control as="select">
                                      <option>Seleccione</option>
                                      <option>Ninguna</option>
                                  </Form.Control>
                                  <Form.Text className="text-muted">
                                      Ingrese el dato
                                  </Form.Text>
                              </Form.Group> */}
                          </div>
                      </Card.Body>
                      <Divider></Divider>
                      <Card.Body>
                          <div className="text-right">
                              <Button disabled={isSubmitting} className="mr-2" variant="secondary">Cancelar</Button>
                            <Button disabled={isSubmitting} onClick={() => handleSubmit()}>{isSubmitting ? 'Enviando' : 'Guardar'}</Button>
                          </div>
                      </Card.Body>
                  </Card>
                )}
              </Formik>}
            </div>
        </div>
    )
}   