/* eslint-disable no-restricted-imports */
import * as React from 'react';
import { Card } from 'react-bootstrap';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Divider } from '@material-ui/core';
import { withRouter } from "react-router";
import OportunitiesTable from '../components/OportunitiesTable';

export interface ProjectsPageProps {
  history: any
}
 
const ProjectsPage: React.SFC<ProjectsPageProps> = (props: ProjectsPageProps) => {
    const [activeSection, setActiveSection] = React.useState<any>(0);
  return (
    <div className="row">
        <div className="col">
            <Card>
                <Card.Header className="justify-content-between d-flex align-items-center">
                    <Card.Title>Atacar Oportunidad</Card.Title>
                </Card.Header>
                <Card.Body>
                <Tabs value={activeSection} indicatorColor="secondary" textColor="secondary" onChange={(_, nv) => setActiveSection(nv)}>
                    <Tab label="Oportunidades Vigentes" />
                    <Tab label="Mis oportunidades" />
                </Tabs>
                <Divider></Divider>
                <div className="my-3">
                    <OportunitiesTable></OportunitiesTable>
                </div>
                </Card.Body>
            </Card>
        </div>
    </div>
  );
}
 
export default withRouter(ProjectsPage);