import { Divider } from '@material-ui/core';
import * as React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import ActivitySummary from './ActivitySummary';
import CActivityLabel from './CActivityLabel';
import AjaxFilter from './AjaxFilter';
import MoreFiltersContainer from './MoreFiltersContainer';
import { Field, useFormikContext } from 'formik';
import TriOptionCheck from '../../ui/TriOptionCheck';
import {useFormikWizard} from 'formik-wizard';


export interface AdvancedFiltersProps {

}


const AdvancedFilters: React.SFC<AdvancedFiltersProps> = (props: AdvancedFiltersProps) => {
  const context = useFormikContext<any>()
  const {values} = useFormikWizard<any>()
  const {cities = []} = values?.basic;
  const { REACT_APP_API_HOST } = process.env;
  const cityLabels = values?.basic?.cities_label?.split(',')
  return (
    <div>
      <div className="section_title">Filtros Avanzados</div>
      <Row>
        {/* form */}
        <Col xs="12" md="8">
          <Row className="justify-content-between">
            <CActivityLabel></CActivityLabel>
            {/* <SaveFilterBtn></SaveFilterBtn> */}
          </Row>
          <Divider className="my-5"></Divider>
          <Row className="pt-5">
              {cities.map((city: string, index: number) => (
                <Form.Group as={Col} md="6">
                  <Form.Label>Localidades ({cityLabels[index]})</Form.Label>
                  <AjaxFilter
                    group={city}
                    onChange={function onChange(options: any) {
                      const opts = options == null ? [] : Array.isArray(options) ? options : [options];
                      const localities_array = context.values.localities_array || [];
                      const filtered_loc = localities_array.filter((loc: any) => loc.municipio_id != city);
                      context.setFieldValue(`localities[${index}]`, options)
                      if (opts.length == 0) {
                        context.setFieldValue('localities_array', filtered_loc)
                      }
                      opts.forEach((opt: any) => {
                        context.setFieldValue('localities_array', [...filtered_loc , { id: opt.value, municipio_id: city }])
                      });
                    }}
                    name={`localities[${index}]`}
                    isMulti
                    url={`/api/v2/meiko/locality/list/170/${city}`}
                  />
                </Form.Group>
              ))}
            <Col xs="6">
              <Form.Group controlId="formHorizontalEmail">
                <Form.Label>
                  Vocación General:
                </Form.Label>

                <AjaxFilter
                  name="vocation"
                  isMulti={true}
                  url={`${REACT_APP_API_HOST}/api/v2/meiko/vocation/list`}
                />
              </Form.Group>
            </Col>
            <Col xs="6">
              <Form.Group controlId="formHorizontalEmail">
                <Form.Label>
                  Nivel Socio Económico:
                </Form.Label>

                <AjaxFilter
                  name="nse"
                  isMulti={true}
                  url={`${REACT_APP_API_HOST}/api/v2/meiko/nse/list`}
                />
              </Form.Group>
            </Col>
          </Row>
          <Divider className="my-3"></Divider>
          <div>
            {/* <Row>
                <Form.Group as={Col} md="3">
                <Field type="switch" id="medios-switch" name="payment_metods" as={TriOptionCheck} withFeedbackLabel={false} label="Medios de Pago" size="lg"></Field>
                </Form.Group>
                <Form.Group as={Col} md="3">
                <Field type="switch" id="domicilios-switch" name="domiciliary" as={TriOptionCheck} withFeedbackLabel={false} label="Domicilios" size="lg"></Field>
                </Form.Group>
                </Row>
                <Divider className="my-3"></Divider>
                */}
            <Row>
              
              {/* <Form.Group as={Col} md="6" controlId="formBasicEmail">
                  <Form.Label>Material POP / Exhibiciones Especiales:</Form.Label>
                  <Select
                  placeholder="Selecciona"
                  options={[
                  {
value: 1,
label: 'Presencia Externa'
}
]}
/>
<Form.Text className="text-muted">
Ingrese el dato
</Form.Text>
</Form.Group> */}
            </Row>
          </div>
        </Col>
        {/* End form*/}
        <Col xs="12" md="4">
          <ActivitySummary></ActivitySummary>
        </Col>
      </Row>
    </div>
  );
}

export default AdvancedFilters;
