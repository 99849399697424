import * as React from 'react';

export interface BaseBadgeProps {
  children: React.ReactNode,
  background: string;
  color: string;
  small?: boolean;
}
 
const BaseBadge: React.SFC<BaseBadgeProps> = (props: BaseBadgeProps) => {
  return (
    <div style={{
      backgroundColor: props.background,
      color: props.color,
      fontSize: props.small ? '0.625rem' : '0.8125rem',
      fontWeight: 500,
      padding: props.small ? '0.3125rem 0.625rem' : '0.9375rem 1.125rem',
      fontFamily: 'Roboto Condensed',
      borderRadius: 5,
    }}>
      {props.children}
    </div>
  );
}
 
export default BaseBadge;