import * as React from 'react';
import FormikWizard from 'formik-wizard'
import {editSteps} from './CommercialActivityEditWizzard.utils'
import SVG from 'react-inlinesvg';
import { Divider } from '@material-ui/core';
import { Button, Card } from 'react-bootstrap';
import Toastr from 'toastr';
import useFetch from 'use-http';
import { useHistory, useParams } from 'react-router';
import Loading from '../../ui/Loading';
import _ from 'lodash'

export interface CommercialActivityEditWizzardProps {
  
}

function FormWrapper({
  children,
  currentStep,
  isLastStep,
  actionLabel,
  isSubmitting,
  goToPreviousStep,
  canGoBack
}: any) {
  return (
    <div>
        <div className="d-flex">
          <div className={`wizard_item ${currentStep === 'basic' && 'active'}`}>
              <div className="wizard_icon"><SVG src="/media/svg/icons/basic_filters.svg"></SVG></div>
              <div className="wizard_label">1. Filtros Básicos</div>
              <div className="arrow">
                  <SVG src="/media/svg/icons/next_arrow.svg"></SVG>
              </div>
          </div>
          <div className={`wizard_item ${currentStep === 'action' && 'active'}`}>
              <div className="wizard_icon"><SVG src="/media/svg/icons/bull.svg"></SVG></div>
              <div className="wizard_label">3. Acción</div>
          </div>
        </div>
      <Divider className="mt-3 mb-5"></Divider>
      {children}
      <Card.Footer className="my-5 pt-4 text-right">
        <Button className="mr-2" type="button" onClick={goToPreviousStep} disabled={!canGoBack || isSubmitting}>
          Atrás
        </Button>
        <Button type="submit" disabled={isSubmitting}>
          {actionLabel || (isLastStep ? isSubmitting ? 'Enviando...' : 'Finalizar' : 'Siguiente')}
        </Button>
      </Card.Footer>
    </div>
  )
}
 
const CommercialActivityEditWizzard: React.FunctionComponent<CommercialActivityEditWizzardProps> = (props: CommercialActivityEditWizzardProps) => {
  const {id} = useParams<any>()
  const history = useHistory()
  const {data, loading} = useFetch(`/api/comercial/action/${id}`, {
    data: {},
    persist: false,
    cache: "no-cache",
    cacheLife: 0
  },  [id]);
  const {response: updateResponse, put: updateAction} = useFetch(`/api/comercial/action/${id}`, {
    data: {},
    persist: false,
    cache: "no-cache",
    cacheLife: 0
  });
  const { data: initialValues = {} } = data;
  const _handleSubmit = React.useCallback(async (values) => {
    let flatValues = {}
    for (let key in values) {
      flatValues = {
        ...flatValues,
        ...values[key]
      }
    }

    await updateAction(flatValues);
    if (!updateResponse.ok) {
      Toastr.error('Error conectando con el servidor');
      return;
    }
    Toastr.success('Información actualizada correctamente');
    history.push(`/acciones-comerciales?project=${initialValues.project_id}`);
  }, [history, initialValues.project_id, updateAction, updateResponse.ok]);


  if (loading) {
    return <Loading></Loading>
  }

  return (
    <FormikWizard
      steps={editSteps(initialValues)}
      onSubmit={_handleSubmit}
      render={FormWrapper}
    />
  );
}
 
export default CommercialActivityEditWizzard;