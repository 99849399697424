import * as React from 'react';
import _ from 'lodash';
import { useFormikContext } from 'formik';
import CommercialActivitiesEditCampaignFields from './CommercialActivitiesEditCampaignFields';
import { useFormikWizard } from 'formik-wizard';
import CommercialActivitiesEditPromoFields from './CommercialActivitiesEditPromoFields';
import CommercialActivitiesEditNewsFields from './CommercialActivitiesEditNewsFields';
import CommercialActivitiesEditChallengeFields from './CommercialActivitiesEditChallengeFields';

export interface CommercialActivitiesEditActionFieldsProps {
  
}
 
const CommercialActivitiesEditActionFields: React.FunctionComponent<CommercialActivitiesEditActionFieldsProps> = (props: CommercialActivitiesEditActionFieldsProps) => {
  const {values} = useFormikContext<any>()
  const {values: wValues} = useFormikWizard<any>();
  
  console.log('values', wValues);
  
  if (values.campaign) {
    return <CommercialActivitiesEditCampaignFields />
  }
  if (values.promotion) {
    return <CommercialActivitiesEditPromoFields />
  }
  if (values.news_message) {
    return <CommercialActivitiesEditNewsFields />
  }
  if (values.challenge) {
    return <CommercialActivitiesEditChallengeFields />
  }

  return null
}
 
export default CommercialActivitiesEditActionFields;