import { Action, Dispatch, ThunkAction } from "@reduxjs/toolkit";
import { FollowCommercialData } from '../../entities/followCommercialRequest';
import { getFollowCommercialById } from "./followCommercialApi";

export const ActionTypes = {
  SET_ERROR: 'F_COMMERCIAL/SET_ERROR',
  SET_DATA: 'F_COMMERCIAL/SET_DATA',
  SET_LOADING: 'F_COMMERCIAL/SET_LOADING',
}

export interface SetErrorAction {
  type: typeof ActionTypes.SET_DATA,
  msg: string
}

export interface SetDataAction {
  type: typeof ActionTypes.SET_DATA,
  payload: FollowCommercialData
}

export interface SetLoadingAction {
  type: typeof ActionTypes.SET_DATA,
  payload: boolean
}

export type FollowCommercialActionTypes = SetErrorAction | SetDataAction | SetLoadingAction;

export function setError(msg: string): SetErrorAction {
  return {
    type: ActionTypes.SET_ERROR,
    msg,
  }
}

export function setData(commercialData: FollowCommercialData):SetDataAction {
  return {
    type: ActionTypes.SET_DATA,
    payload: commercialData,
  }
}

export function setLoading(isLoading: boolean): SetLoadingAction {
  return {
    type: ActionTypes.SET_LOADING,
    payload: isLoading,
  }
}

export function fetchFollowCommercialById(actionId: string | number):
  ThunkAction<void, any, unknown, Action<string>> {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true))
      const res = await getFollowCommercialById(actionId);
      dispatch(setData(res.data));
    } catch(ex) {
      setError(ex.message)
    }
  }
}