/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import {  checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <i className="icon-bars"></i>
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/proyectos", false)}`}
        >
          <NavLink className="menu-link" to="/proyectos">
            <span className="svg-icon menu-icon">
              <i className="icon-box"></i>
            </span>
            <span className="menu-text">Proyectos</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/atacar-oportunidad", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/atacar-oportunidad">
            <span className="svg-icon menu-icon">
              <i className="icon-bullhorn"></i>
            </span>
            <span className="menu-text">Atacar Oportunidad</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/seguimiento", false)}`}
        >
          <NavLink className="menu-link" to="/seguimiento">
            <span className="svg-icon menu-icon">
              <i className="icon-graph"></i>
            </span>
            <span className="menu-text">Realizar Seguimiento</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level
        <li
          className={`menu-item ${getMenuItemActive("/promociones", false)}`}
        >
          <NavLink className="menu-link" to="/promociones">
            <span className="svg-icon menu-icon">
              <i className="fa fa-store"></i>
            </span>
            <span className="menu-text">Pedidos Promociones</span>
          </NavLink>
        </li>
        /*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/notificaciones", false)}`}
        >
          <NavLink className="menu-link" to="/notificaciones">
            <span className="svg-icon menu-icon">
              <i className="fa fa-bell"></i>
            </span>
            <span className="menu-text">Notificaciones</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}



      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
