
import FullCalendar, { EventClickArg, EventContentArg } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Divider } from '@material-ui/core';
import { Formik } from 'formik';
import _ from 'lodash';
import React from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import useFetch from 'use-http';
import { DatePickerField } from '../../../../_metronic/_partials/controls';
import AjaxFilter from '../../commercialActivities/components/AjaxFilter';
import DetailEvent from './DetailEvent';
import EventRender from './EventRender';


export interface FollowPageProps {
  match: any
  history?: any
}
 
const FollowPage: React.SFC<FollowPageProps> = (props: FollowPageProps) => {
  const { REACT_APP_API_HOST } = process.env;
  const {data = {}, request, response} = useFetch(`${process.env.REACT_APP_API_HOST}/api/tracing/initial/filter`)
  const {data: items = {}} = data;
  
  const { id } = props.match.params;

  const renderEventContent = (eventContent: EventContentArg) => {
    return <EventRender eventContent={eventContent} />;
  };

  const handleEventClick = (eventClick: EventClickArg) => {
    props.history.push(`/seguimiento-actividad/${eventClick.event.id}`);
  }
  

  if(id){
    return <DetailEvent />
  }

  const values = _.values(items)

  return (
    <Row>
        <Col xl={12}>
          <Card>
            <Card.Header>
              <Card.Title>Realizar Seguimiento</Card.Title>
            </Card.Header>
            <Card.Body>
              <Formik
                initialValues={{
                  category: '',
                  city_id: '',
                  country_id: '',
                  brand_id: '',
                  project_id: '',
                  store_channel_id: '',
                  action_type_id: '',
                }}
                onSubmit={async (values: any) => {
                  await request.post(values)
                  if (!response.ok) {
                    Swal.fire({
                      title: 'Error',
                      text: 'Error cargando los filtros' 
                    })
                  } 
                }}
              >
                {({ values, handleSubmit, isSubmitting, handleChange, handleBlur, touched }) => (
                  <Form>
                    <Row>
                      <Form.Group as={Col} md="4" controlId="formTracing">
                        <Form.Label>Proyecto</Form.Label>
                        <AjaxFilter
                          labelField="name"
                          name="project_id"
                          url={`${REACT_APP_API_HOST}/api/project/select/get`}
                        />
                        <Form.Text className="text-muted">
                          Ingrese el nombre
                        </Form.Text>
                      </Form.Group>
                      {/**<Form.Group as={Col} md="4" controlId="formTracing">
                        <Form.Label>Mercados</Form.Label>
                        <AjaxFilter
                          name="city_id"
                          isMulti
                          url={`${REACT_APP_API_HOST}/api/meiko/city/list/170`}
                        />
                        <Form.Text className="text-muted">
                          Ingrese el nombre
                        </Form.Text>
                      </Form.Group>
                      <Form.Group as={Col} md="4" controlId="formTracing">
                        <Form.Label>Canal</Form.Label>
                        <AjaxFilter
                          name="store_channel_id"
                          isMulti
                          url={`${REACT_APP_API_HOST}/api/meiko/store/channel/list/170`}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="4" controlId="formTracing">
                        <Form.Label>Categoría</Form.Label>
                        <AjaxFilter
                          name="category"
                          url={`${REACT_APP_API_HOST}/api/meiko/category/list`}
                        />
                        </Form.Group>**/}
                      {values.category && <Form.Group as={Col} md="4" controlId="formTracing">
                        <Form.Label>Productos</Form.Label>
                        <AjaxFilter
                          name="brand_id"
                          url={`${REACT_APP_API_HOST}/api/meiko/brand/list/${values.category}`}
                          watch={[values.category]}
                        />
                      </Form.Group>}
                      <Form.Group as={Col} md="4" controlId="formTracing">
                        <Form.Label>Tipo de acción</Form.Label>
                        <AjaxFilter
                          labelField="name"
                          name="action_type_id"
                          url={`${REACT_APP_API_HOST}/api/types/action/type/list`}
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Col xl={6}>
                        <h3 className="font-weight-bolder">Período</h3>
                        <Divider className="my-3" />
                        <Row>
                          <Form.Group as={Col} md="6" controlId="formTracing">
                            <Form.Label>Desde</Form.Label>
                              <div>
                                <DatePickerField name="start" />
                              </div>
                          </Form.Group>
                          <Form.Group as={Col} md="6" controlId="formTracing">
                            <Form.Label>Hasta</Form.Label>
                              <div>
                                <DatePickerField name="end" />
                              </div>
                          </Form.Group>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={2} style={{marginTop: 35}}>
                        <Button disabled={isSubmitting} onClick={() => handleSubmit()} style={{
                            minWidth: 120
                          }}>{isSubmitting ? 'Filtrando...' : 'Filtrar'}</Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} style={{marginTop: 25}}>
          <Card>
            <Card.Body>
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay'
                }}
                buttonText={{
                  prev:     '<', // <
                  next:     '>', // >
                  prevYear: '<<',  // <<
                  nextYear: '>>',  // >>
                  today:    'hoy',
                  month:    'mes',
                  week:     'semana',
                  day:      'dia'
                }}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                initialView="dayGridMonth"
                themeSystem="lumen"
                locale="es"
                timeZone="local"
                events={values.map(v => (
                  {
                    id: v.id,
                    title: v.name,
                    start: new Date(v.start).toISOString().replace(/T.*$/, ''),
                    end: new Date(v.end).toISOString().replace(/T.*$/, ''),
                    color: '#ffb70e',
                    textColor: 'white',
                    extendedProps: {
                      state: true,
                      message: v.description,
                    },
                  }
                ))}
                eventContent={renderEventContent}
                eventClick={handleEventClick}
              />
            </Card.Body>
          </Card>
        </Col>
    </Row>
  );
}
 
export default FollowPage;
