import { Field, FieldArray, useFormikContext } from 'formik';
import * as React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import EditorField from './EditorField';
import _ from 'lodash';
import UploadFileField from './UploadFileField';
import { QuestionsList } from './QuestionsList';

export interface PollFormProps {

}

const PollForm: React.SFC<PollFormProps> = () => {
  const { values } = useFormikContext<any>()
  const questions = _.get(values, 'campaign.questions', [])

  return (
    <>
      <Row>
        <Form.Group as={Col} md="6" controlId="formBasicEmail">
          <Form.Label>Nombre:</Form.Label>
          <Field name="campaign.name" as={Form.Control}></Field>
          <Form.Text className="text-muted">
            Ingrese el dato
                    </Form.Text>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="formBasicEmail">
          <Form.Label>Descripción:</Form.Label>
          <Field name="campaign.description" as={Form.Control}></Field>
          <Form.Text className="text-muted">
            Ingrese el dato
          </Form.Text>
        </Form.Group>
      </Row>
      <div className="bold">Duración</div>
      <Row className="mt-2">
        <Form.Group as={Col} md="6" controlId="formBasicEmail">
          <Form.Label>Fecha Inicial:</Form.Label>
          <Field as={Form.Control} name="campaign.start" type="datetime-local"></Field>
          <Form.Text className="text-muted">
            Ingrese el dato
                        </Form.Text>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="formBasicEmail">
          <Form.Label>Fecha Final:</Form.Label>
          <Field as={Form.Control} name="campaign.end" type="datetime-local"></Field>
          <Form.Text className="text-muted">
            Ingrese el dato
                        </Form.Text>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>Imagen encabezado</Form.Label>
          <UploadFileField name="campaign.photo" url={`${process.env.REACT_APP_API_HOST}/api/comercial/action/image/save`}></UploadFileField>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} md="12" controlId="formBasicEmail">
          <Form.Label>Descripción Cuestionario:</Form.Label>
          <Field component={EditorField} name="campaign.instructions" />
          <Form.Text className="text-muted">
            Ingrese el dato
          </Form.Text>
        </Form.Group>
      </Row>
      {/* <Row className="text-secondary">
        <Col className="d-flex">
          <div><span className="bold">CRÉDITOS:</span> <span className="f-22">90</span> puntos</div>
          <div className="ml-5"><span className="f-22">-10</span> puntos</div>
        </Col>
      </Row> */}
      <Row className="f-14" style={{ marginBottom: 30 }}>
        <Col>
          Cada pregunta de seleccion equivale a 10 puntos, cada pregunta tipo texto equivale a 20 puntos.
                </Col>
      </Row>
      <FieldArray
        name="campaign.questions">
        {(helpers) => (
          <QuestionsList
            questions={questions}
            helpers={helpers}
          ></QuestionsList>
        )}
      </FieldArray>
    </>
  );
}

export default PollForm;

