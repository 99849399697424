import * as React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import FollowProjectInfo from "../components/FollowProjectInfo";
import { fetchFollowCommercialById } from "../_redux/followCommercial/FollowCommercialActions";

export interface FollowActionDetailPageProps {}

interface RouteParams {
  id: any;
}

const FollowActionDetailPage: React.SFC<FollowActionDetailPageProps> = (
  props: FollowActionDetailPageProps
) => {
  /* const commercialData = useSelector(selectCommercialData); */
  const params = useParams<RouteParams>();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!params.id) {
      Swal.fire({
        icon: 'error',
        text: 'debe especificar el identificador de la acción comercial'
      })
    }
    
    dispatch(fetchFollowCommercialById(params.id));
  }, [dispatch, params, params.id]);

  return (
    <div>
      <FollowProjectInfo />
    </div>
  );
};

export default FollowActionDetailPage;
