import * as React from 'react';
import ColorCard from '../../ui/ColorCard';

export interface CounterCardProps {
  color: string,
  logo: string,
  label: string,
  customColor?: string,
  count: any,
}
 
const CounterCard: React.SFC<CounterCardProps> = (props: CounterCardProps) => {
  return (
    <ColorCard color={props.color}>
      <img alt="Logo" src={props.logo} width="40" />
      <div  style={{
        fontSize: 50,
        fontWeight: 'bold',
        textAlign: 'right',
        color: props.customColor || 'white'
      }}>{props.count}</div>
      <div  style={{
        fontSize: 15,
        textAlign: 'right',
        color: props.customColor || 'white'
      }}>{props.label}</div>
    </ColorCard>
  );
}
 
export default CounterCard;