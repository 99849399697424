import * as React from 'react';

export interface DragAndDropListProps {
  index: number,
  children: React.ReactNode,
  onDrop: (dragIndex: number, dropIndex: number) => void;
}
 
const DragAndDropItem: React.FunctionComponent<DragAndDropListProps> = ({
  index,
  children,
  onDrop,
}: DragAndDropListProps) => {
  const _handleDragStart = React.useCallback((ev) => {
    ev.dataTransfer.setData("index", String(index));
  }, [index]);

  const _handleDragOver = React.useCallback((ev) => {
    ev.preventDefault();
  }, []);

  const _handleDrop = React.useCallback((ev) => {
    ev.preventDefault();
    const dragIndex = ev.dataTransfer.getData("index");
    onDrop(dragIndex, index)
  }, [index, onDrop]);

  return (
    <div
      draggable="true"
      onDragStart={_handleDragStart}
      onDragOver={_handleDragOver}
      onDrop={_handleDrop}
    >{children}</div>
  );
}
 
export default DragAndDropItem;