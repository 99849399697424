import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Field } from 'formik';
import * as React from 'react';

export interface RadioInputProps {
  options: {label: string, value: string}[],
  name: string,
}
 
const RadioInput: React.SFC<RadioInputProps> = ({name, options}: RadioInputProps) => {
  return (
    <Field name={name}>
      {({field, form}: any) => (
        <>
          <RadioGroup
            value={String(field.value || '')}
            onChange={(event: any) => {
              form.setFieldValue(name, event.target.value)
            }}
          >
            {options.map((op) => (
              <FormControlLabel
                key={op.value}
                value={String(op.value)}
                control={<Radio />}
                label={op.label} />
            ), [])}
          </RadioGroup>
        </>
      )}
    </Field>
  );
}
 
export default RadioInput;