import * as React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import CActivitiesTable from '../CActivitiesTable';

export interface CActivitiesPageProps {
  
}
 
const CActivitiesPage: React.SFC<CActivitiesPageProps> = (props: CActivitiesPageProps) => {
  return (
    <Row>
        <Col>
            <Card>
                <Card.Header>
                    <Card.Title></Card.Title>
                </Card.Header>
                <Card.Body>
                    <CActivitiesTable></CActivitiesTable>
                </Card.Body>
            </Card>
        </Col>
    </Row>
  );
}
 
export default CActivitiesPage;