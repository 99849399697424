import * as React from 'react';
import DragAndDropItem from './DragAndDropItem';

export interface DragAndDropListProps<T> {
  renderItem: (item: T, index: number) => React.ReactNode,
  data: T[],
  onDropEnd: (dragIndex: number, dropIndex: number) => void
}
 
function DragAndDropList<T>({
  renderItem,
  onDropEnd,
  data
}: DragAndDropListProps<T>){
  return (
    <>
      {data.map((item, index) => (
        <DragAndDropItem
          key={index}
          onDrop={onDropEnd}
          index={index}
        >
          {renderItem(item, index)}
        </DragAndDropItem>
      ))}
    </>
  );
}
 
export default DragAndDropList;