// @eslint-disable-next-line
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const miqApi: any = createApi({
  reducerPath: 'miq',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_HOST,
    prepareHeaders: (headers, { getState }) => {
      headers.set('authorization', `Bearer ${localStorage.getItem('token')}`)
      return headers
    }
  }),
  endpoints: (builder: any) => ({
    getCountries: builder.query({ query: () => '/api/v2/meiko/country/list' }),
    getCities: builder.query({ query: (country: string | number) => `/api/v2/meiko/city/list/${country}` }),
    getChannels: builder.query({ query: (country: string | number) => `/api/v2/meiko/store/channel/list/${country}` }),
  })
})


 export const { useGetCountriesQuery, useGetCitiesQuery, useGetChannelsQuery } = miqApi;
