import * as React from 'react';
import Select, { OptionsType, Props as SelectProps, ValueType } from 'react-select';
import useFetch from 'use-http';
import _ from 'lodash';
import { PromoListResponse } from '../entities/PromoListResponse';
import { orderIdNulleable } from '../hooks/useOrderList';
import { Option } from 'react-select/src/filters';

export interface PromoSelectProps extends SelectProps {
  selectedId: orderIdNulleable,
  setSelectedId: (nextId: string) => void,
}
 
const PromoSelect: React.SFC<PromoSelectProps> = ({selectedId, setSelectedId, ...props}: PromoSelectProps) => {
  const {data, loading} = useFetch<PromoListResponse>('/api/promotions/comercial/actions/list', {}, [])

  const options: OptionsType<any> = React.useMemo(() => {
    if (loading) {
      return [
        {
          label: 'Cargando...',
          value: ''
        },
      ]
    }
    const items = data?.data ?? [];
    return [
      {
        label: 'Seleccione',
        value: ''
      },
      ...items.map(i => ({
        label: _.get(i, 'promotion[0].name', 'Sin nombre'),
        value: _.get(i, 'promotion[0].id', '')
      })),
    ]
  }, [data, loading]);

  const value = React.useMemo(() => {
    return _.find(options, (v: Option) => v.value == selectedId);
  }, [options, selectedId])
  

  return (
    <Select
      {...props}
      value={value}
      options={options}
      onChange={(nv: ValueType<any>) => {
        setSelectedId(nv.value);
      }}
    />
  );
}
 
export default PromoSelect;