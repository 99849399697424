import axios from "axios";
import swal from 'sweetalert2';

export const LOGIN_URL = `${process.env.REACT_APP_API_HOST}/api/oauth/login`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = `${process.env.REACT_APP_API_HOST}/api/oauth/me`;

export function login(username, password) {
  const fd = new FormData()
  fd.append('username', username)
  fd.append('password', password)
  fd.append('grant_type', 'password')
  fd.append('client_id', 2)
  fd.append('client_secret', 'KURIWRWCcVVqVt235f8W0MRtjM8mSpjnlZpgqsVj')
  fd.append('scope', '*')

  return fetch(LOGIN_URL, {
    method: 'post',
    body: fd,
    headers: {
      Accept: 'application/json',
      'X-Language': 'es'
    }
  });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export async function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  /* console.log(ME_URL) */
  return fetch(ME_URL, {
    headers: {
      Accept: 'application/json',
      'X-Language': 'ES',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  }).then((res) => {
    if (res.status === 401) {
      swal.fire({
          text: "Tu sesión expiró",
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Ok",
          closeOnConfirm: false
      }).then(() => {
        localStorage.clear();
        window.location.href = '/auth/login'
      })
      return {};
    }
    return res.json();
  });
}
