
import { Field, FieldArray, useFormikContext } from 'formik';
import * as React from 'react';
import { Col, Row, FormGroup, Form, Alert } from 'react-bootstrap';
import FormikBootstrapInput from '../../ui/FormikBootstrapInput';
import EditorField from './EditorField';
import { QuestionsList } from './QuestionsList';
import UploadFileField from './UploadFileField';
import _ from 'lodash'

export interface CommercialActivitiesEditCampaignFieldsProps {
  
}
 
const CommercialActivitiesEditCampaignFields: React.FunctionComponent<CommercialActivitiesEditCampaignFieldsProps> = (props: CommercialActivitiesEditCampaignFieldsProps) => {
  const { values } = useFormikContext<any>()
  const questions = _.get(values, 'campaign.questions', [])
  

  return (
    <div>
      <Row>
        <Col>
        <FormGroup>
          <Form.Label>Nombre de la acción comercial:</Form.Label>
          <FormikBootstrapInput label="Nombre" name="campaign.name"></FormikBootstrapInput>
        </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Form.Label>Fecha de inicio:</Form.Label>
            <FormikBootstrapInput type="datetime-local" name="campaign.start"></FormikBootstrapInput>
          </FormGroup>
        </Col>
        <Col>
        <FormGroup>
          <Form.Label>Fecha de finalización:</Form.Label>
          <FormikBootstrapInput type="datetime-local" name="campaign.end"></FormikBootstrapInput>
        </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Form.Label>Descripción:</Form.Label>
            <Field component={EditorField} name="campaign.description"></Field>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Form.Label>Imágen de referencia:</Form.Label>
            <UploadFileField url={`${process.env.REACT_APP_API_HOST}/api/comercial/action/image/save`} name="campaign.photo"></UploadFileField>
          </FormGroup>
        </Col>
      </Row>
      {values.answers_stored ? (
        <Alert variant="info">
          <i className="fas fa-exclamation-triangle mr-2 text-info"></i>
          Esta encuesta ya tiene asociadas respuestas, por tanto no puedes actualizar las preguntas
        </Alert>
      ) : (
        <FieldArray
          name="campaign.questions">
          {(helpers) => (
            <QuestionsList
              questions={questions}
              helpers={helpers}
            ></QuestionsList>
          )}
        </FieldArray>
      )}
    </div>
  );
}
 
export default CommercialActivitiesEditCampaignFields;