import { Divider } from '@material-ui/core';
import * as React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import ActivitySummary from './ActivitySummary';
import SaveFilterBtn from './SaveFilterBtn';
// eslint-disable-next-line no-restricted-imports
import CActivityLabel from './CActivityLabel';
import AjaxFilter from './AjaxFilter';
import { useFormikContext } from 'formik';
import ActionTabs from './ActionTabs'
import {useFormikWizard} from 'formik-wizard';
import PollForm from './PollForm';
import ChallengeForm from './ChallengeForm';
import NewsForm from './NewsForm';

export enum ActionTypes {
  poll=48,
  challenge=49,
  news=50
}

const Strategic = () => {

  const {values} = useFormikContext<any>()
  const wizard = useFormikWizard<any>()
  const {comercial_action_type_id} = wizard?.values?.basic;

  return (
    <div>
      <div className="section_title">Filtros Estratégicos</div>
      <Row>
        {/* form */}
        <Col xs="12" md="8">
          <Row className="justify-content-between">
            <CActivityLabel></CActivityLabel>
          </Row>
          <Divider className="my-5"></Divider>
          <Divider className="my-4" style={{
            backgroundColor: '#F15E3D'
            }}></Divider>
          {comercial_action_type_id == ActionTypes.poll && <PollForm></PollForm>}
          {comercial_action_type_id == ActionTypes.challenge && <ChallengeForm></ChallengeForm>}
          {comercial_action_type_id == ActionTypes.news && <NewsForm></NewsForm>}
        </Col>
        {/* End form*/}
        <Col xs="12" md="4">
          <ActivitySummary></ActivitySummary>
        </Col>
      </Row>
    </div>
  );
}

export default Strategic;
