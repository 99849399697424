import React from "react";
import {  Col, Row } from "react-bootstrap";
import ActionsComCount from "../../../app/modules/Dashboard/components/ActionsComCount";
import PromoCount from "../../../app/modules/Dashboard/components/PromoCount";
import RetosCounter from "../../../app/modules/Dashboard/components/RetosCounter";
import PollsCounter from "../../../app/modules/Dashboard/components/PollsCounter";
import NewsCounter from "../../../app/modules/Dashboard/components/NewsCounter";
import PdvsImpacted from "../../../app/modules/Dashboard/components/PdvsImpacted";
import useFetch from "use-http";

export function Dashboard() {
  const {data = {}} = useFetch(`${process.env.REACT_APP_API_HOST}/api/kpi/comercial/actions`, {}, [])
  const {data: stats = {}} = data
  
    return <>
      <Row>
        <Col md="4" >
          <ActionsComCount total={stats.comercial_action}></ActionsComCount>
        </Col>
        <Col md="2">
          <RetosCounter total={stats.challenge}></RetosCounter>
        </Col>
        <Col md="2">
          <PollsCounter total={stats.contest}></PollsCounter>
        </Col>
        <Col md="2">
          <NewsCounter total={stats.news}></NewsCounter>
        </Col>
      </Row>
      <Row className="mt-5">
        {/* <Col md="4" >
          <TotalInvest></TotalInvest>
        </Col> */}
        {/* <Col md="4" >
          <EffectivityCount></EffectivityCount>
        </Col> */}
        <Col md="3" >
          <PdvsImpacted></PdvsImpacted>
        </Col>
        {/* <Col md="3" >
          <AccumImpacts></AccumImpacts>
        </Col> */}
      </Row>
    </>;
}
