import * as React from 'react';

export interface ListEmptyProps {
  text: string;
  iconClass?: string;
}
 
const ListEmpty: React.FunctionComponent<ListEmptyProps> = ({
  iconClass = 'fas fa-th-list',
  text = 'No hay elementos para mostrar en este listado'
}: ListEmptyProps) => {
  return (
    <div
      style={{
        fontSize: '1.7rem',
        color: '#ccc',
        lineHeight: '2.4rem',
        maxWidth: 700,
        textAlign: 'center'
      }}
    >
      <i className={iconClass} style={{
        fontSize: '3rem',
        marginBottom: '1rem',
      }}></i>
      <div>{text}</div>
    </div>
  );
}
 
export default React.memo(ListEmpty);