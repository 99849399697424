// @ts-nocheck
import { Divider } from '@material-ui/core';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import ActivitySummary from './ActivitySummary';
import BasicFilterFields from './BasicFilterFields';
import CActivityLabel from './CActivityLabel';


export interface BasicFiltersProps {

}

const BasicFilters: React.SFC<BasicFiltersProps> = (props: BasicFiltersProps) => {
  return (
    <div>
      <div className="section_title">Filtros Básicos</div>
      <Row>
        {/* form */}
        <Col xs="12" md="8">
          <Row className="justify-content-between">
            <CActivityLabel></CActivityLabel>
          </Row>
          <Divider className="my-3"></Divider>
          <BasicFilterFields></BasicFilterFields>
          <Divider className="my-3"></Divider>

        </Col>
        {/* End form*/}
        <Col xs="12" md="4">
          <ActivitySummary></ActivitySummary>
        </Col>
      </Row>
    </div>
  );
}

export default BasicFilters;
