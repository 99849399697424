import { Divider, Switch } from '@material-ui/core';
import { Field, FieldArray, useFormikContext } from 'formik';
import * as React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import _ from 'lodash'

export interface QuestionOptionsProps {
  questionIndex: number,
  enableGoTo?: boolean,
}

const QuestionOptions: React.SFC<QuestionOptionsProps> = ({
  enableGoTo,
  questionIndex
}: QuestionOptionsProps) => {
  const {values, setFieldValue} = useFormikContext<any>();
  const fieldName = `campaign.questions[${questionIndex}].options`
  const questions = _.get(values,`campaign.questions`, [])

  const isLastQuestion = questions.length === questionIndex + 1
  return (
    <FieldArray
      name={fieldName}>
      {(helpers) => (
        <>
          {values.campaign.questions[questionIndex].options.map((i: any, index: any) => (
            <>
              <Row className="py-2">
                <Col>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center w-100 px-2">
                      <SVG src="/media/svg/icons/drag_vert.svg"></SVG>
                      <Field className="ml-2" style={{ flex: 1 }} as={Form.Control} name={`${fieldName}[${index}].name`}></Field>
                    </div>
                    <button onClick={() => helpers.remove(index)} className="circular bg-orange d-flex align-items-center justify-content-center" style={{
                      width: 18,
                      height: 18,
                    }}>
                      <i className="fa fa-times" style={{
                        fontSize: 12,
                        color: 'white'
                      }}></i>
                    </button>
                  </div>
                </Col>
                {enableGoTo  && <Col style={{
                  alignItems: 'center'
                }}>
                  <>
                    <Field as="select" className="form-control" name={`${fieldName}[${index}].go_to`}>
                      <option>{!isLastQuestion ? 'Redirige a siguiente pregunta' : 'Envía formulario'}</option>
                      {questions.slice(questionIndex + 1, questions.length).map((question: any, index: number) => (
                        <option value={questionIndex + index + 1}>Pregunta {questionIndex + 1 + index + 1}: {question.name}</option>
                      ))}
                    </Field>
                    <Form.Label className="small">Cuando se seleccione esta opción redigirá a esta pregunta</Form.Label>
                  </>
                </Col>}
              </Row>
              <Row style={{
                alignItems: 'center'
              }}>
                <Col>
                <Form.Label>Respuesta Correcta</Form.Label>
                <Field
                  color="primary"
                  as={Switch}
                  checked={!!_.get(values, `${fieldName}[${index}].correct`)}
                  name={`${fieldName}[${index}].correct`}
                  onChange={() => {
                    setFieldValue(`${fieldName}[${index}].correct`, !_.get(values, `${fieldName}[${index}].correct`))
                  }}
                />
                </Col>
              </Row>
              <Row style={{
                alignItems: 'center'
              }}>
                <Col>
                <Form.Label>opción otra ¿cual?</Form.Label>
                <Field
                  color="primary"
                  as={Switch}
                  checked={!!_.get(values, `${fieldName}[${index}].other_which_one`)}
                  onChange={() => {
                    setFieldValue(`${fieldName}[${index}].other_which_one`, !_.get(values, `${fieldName}[${index}].other_which_one`))
                  }}
                  name={`${fieldName}[${index}].other_which_one`}
                />
                </Col>
              </Row>
              <Divider></Divider>
            </>
          ))}
          <button type="button" onClick={() => {
            helpers.push({
              correct: false,
              other_which_one: false,
              order: values.campaign.questions[questionIndex].options.length
            })
          }} className="btn text-right text-secondary bold mt-3 cursor-pointer">Agregar opción +</button>
        </>
      )}
    </FieldArray>
  );
}

export default QuestionOptions;