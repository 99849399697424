/* eslint-disable no-template-curly-in-string */
const yup_es = {
  mixed: {
    default: "No es válido",
    required: "El campo '${label}' es requerido"
  },
  number: {
    min: "Mínimo ${min}"
  },
  date: {
    min: "La fecha debe ser mayor a ${min}",
    max: "La fecha debe ser menor a ${max}",
  },
}

export default yup_es;