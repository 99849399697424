import * as React from 'react';
import { Badge, Button, Form, Row, Table } from 'react-bootstrap';
import PaginationPaginationSize from '../Navigation/components/PaginationPaginationSize';
import PaginationTables from '../Navigation/components/PaginationTables';
import { withRouter, RouteComponentProps } from 'react-router';
import useFetch from 'use-http';
import qs from 'qs';
import { getGenericHeaders } from '../../../redux';
import ButtonTooltip from '../ui/ButtonTooltip';
import Loading from '../ui/Loading';
import ListEmpty from '../ui/Lists/ListEmpty';

const actionStatus = {
  PENDIENTE: 7,
  EJECUCION: 8,
  EJECUTADA: 9,
  PAUSADA: 102,
}

export interface CActivitiesTableProps extends RouteComponentProps {
  history: any
}

const CActivitiesTable: React.SFC<CActivitiesTableProps> = (props) => {
  const query = qs.parse(props.location.search, { ignoreQueryPrefix: true })
  const { data: res = {}, get, cache, loading } = useFetch(`${process.env.REACT_APP_API_HOST}/api/comercial/action?page=1&pageSize=20&project_id=${query.project}`, {
    headers: getGenericHeaders(),
    cacheLife: 0
  }, [query.project])
  const {put: updateStatus, loading: isUpdatingState} = useFetch(`${process.env.REACT_APP_API_HOST}/api/comercial/action/toggle/status`, {
    cacheLife: 0,
  });
  const { data = [] } = res;

  const getStatusColors = React.useCallback((id?: any): any => {
    
    if (id === actionStatus.PENDIENTE) {
      return {
        color: 'white',
        borderColor: '#74A4FF',
        backgroundColor: '#1D71F3',
      }
    }
    if (id === actionStatus.EJECUCION) {
      return {
        color: '#05C55C',
        borderColor: '#05C55C',
        backgroundColor: 'rgba(5,197,92,0.32)',
      }
    }
    if (id === actionStatus.PAUSADA) {
      return {
        color: 'white',
        borderColor: '#FF5A66',
        backgroundColor: '#FF5A66',
      }
    }
    if (id === actionStatus.EJECUTADA) {
      return {
        color: 'white',
        borderColor: '#FF8900',
        backgroundColor: '#FF8900',
      }
    }
    return {
      color: 'white',
      borderColor: 'black',
      backgroundColor: 'black',
    }
  }, [])

  
  const refreshData = React.useCallback(() => {
    cache.clear();
    get();
  }, [cache, get])
  return (
    <>
      <Row className="py-5 justify-content-end">
        <Button onClick={() => {
          props.history.push(`/nueva-accion?project=${query.project}`)
        }}>Crear Acción comercial</Button>
      </Row>
      <Row>
        <Table responsive className="text-center">
          <thead>
            <tr>
              <th>
                <Form.Check></Form.Check>
              </th>
              <th>ID</th>
              <th>NOMBRE</th>
              <th>DESCRIPCIÓN</th>
              <th>TIPO DE ACCIÓN</th>
              {/* <th>CATEGORÍA</th> */}
              {/* <th>MARCA</th> */}
              <th>PDVs</th>
              <th>ESTADO</th>
              <th>ACCIÓN</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={7}>
                  <Loading />
                </td>
              </tr>
            )}
            {!loading && data.length === 0 && (
              <tr>
                <td colSpan={7} align="center" style={{
                  paddingTop: '2rem',
                  paddingBottom: '2rem'
                }}>
                  <ListEmpty
                    text={"No se han creado acciones comerciales para este proyecto haz clic en el botón 'Crear acción comercial' para crear una."}
                  />
                </td>
              </tr>
            )}
            {data.map((d: any) => (
              <tr key={String(d.id)}>
                <td><Form.Check></Form.Check></td>
                <td>{d.id}</td>
                <td>{d.name}</td>
                <td>{d.description}</td>
                <td>{d.comercial_action_type.name}</td>
                {/* <td>Lorem ipsum</td> */}
                {/* <td>{d.brand}</td> */}
                <td>{d.pdvs}</td>
                <td> <Badge variant="secondary" style={{
                  color: getStatusColors(d.status.id).color,
                  borderColor: getStatusColors(d.status.id).borderColor,
                  backgroundColor: getStatusColors(d.status.id).backgroundColor,
                }}>{d.status.name}</Badge></td>
                <td>
                  <div className="d-flex align-items-center justify-content-center">
                      <ButtonTooltip
                        href={`/action-map?id=${d.id}`}
                        disabled={isUpdatingState}
                        className="mr-2"
                        tooltipText="Ver mapa"
                        variant="info"
                        /* size="sm" */
                      >
                        <i className={`fa ${isUpdatingState ? 'fa-spinner' : 'fa-map'}`}></i>
                    </ButtonTooltip>
                    {d.status.id === actionStatus.PENDIENTE && <ButtonTooltip
                      disabled={isUpdatingState}
                      onClick={async () => {
                        await updateStatus(`/${d.id}`, {
                          status: 8
                        })
                        refreshData()
                      }}
                      tooltipText="Ejecutar acción"
                      className=""
                      variant="success"
                      /* size="sm" */
                    >
                      <i className={`fa ${isUpdatingState ? 'fa-spinner' : 'fa-play-circle'}`}></i>
                    </ButtonTooltip>}
                    <ButtonTooltip
                      disabled={isUpdatingState}
                      onClick={() => {
                        // @todo: Corregir error de cache react router para evitar renderizar pagina completa
                        window.location.href = `/accion-comercial/${d.id}`
                      }}
                      tooltipText="Editar acción"
                      className="ml-2"
                      variant="primary"
                      /* size="sm" */
                    >
                      <i className={`fa ${isUpdatingState ? 'fa-spinner' : 'fa-edit'}`}></i>
                    </ButtonTooltip>
                    {d.status.id === actionStatus.EJECUCION && 
                    <>
                      <ButtonTooltip
                        disabled={isUpdatingState}
                        onClick={async () => {
                          await updateStatus(`/${d.id}`, {
                            status: actionStatus.PAUSADA
                          })
                          refreshData()
                        }}
                        tooltipText="Pausar"
                        className=""
                        variant="warning"
                        /* size="sm" */
                      >
                        <i className={`fa ${isUpdatingState ? 'fa-spinner' : 'fa-pause'}`}></i>
                      </ButtonTooltip>
                      <ButtonTooltip
                        disabled={isUpdatingState}
                        className="ml-2"
                        onClick={async () => {
                          await updateStatus(`/${d.id}`, {
                            status: actionStatus.EJECUTADA
                          })
                          refreshData()
                        }}
                        tooltipText="Finalizar"
                        variant="danger"
                        /* size="sm" */
                      >
                        <i className={`fa ${isUpdatingState ? 'fa-spinner' : 'fa-stop'}`}></i>
                      </ButtonTooltip>
                    </>
                    }
                    {d.status.id === actionStatus.PAUSADA && <ButtonTooltip
                      disabled={isUpdatingState}
                      onClick={async () => {
                        await updateStatus(`/${d.id}`, {
                          status: actionStatus.EJECUCION
                        })
                        refreshData()
                      }}
                      tooltipText="Reanudar"
                      className=""
                      variant="success"
                      /* size="sm" */
                    >
                      <i className={`fa ${isUpdatingState ? 'fa-spinner' : 'fa-play'}`}></i>
                    </ButtonTooltip>}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
      <div className="d-flex justify-content-between align-items-center">
        <PaginationTables></PaginationTables>
        <PaginationPaginationSize></PaginationPaginationSize>
      </div>
    </>
  );
}

export default withRouter(CActivitiesTable);