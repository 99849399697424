import * as React from 'react';
import useFetch from 'use-http';
import { getGenericHeaders } from '../../../../redux';
import { Select } from '../../../../_metronic/_partials/controls';
import _ from 'lodash';

export interface AjaxFilterProps {
  name: string,
  url: string,
  group?: string,
  watch?: any[],
  isReady?: boolean,
  value?: any,
  isMulti?: any;
  onChange?: any,
  callFor?: any[],
  labelField?: string
}

const AjaxFilter: React.SFC<AjaxFilterProps> = ({url, name, watch = [], isReady = true, labelField = 'descripcion', ...props}: AjaxFilterProps) => {
  const { data: res = {}, loading, error, get, cache } = useFetch(url, {
    headers: getGenericHeaders(),
    /* suspense: true, */
  });


  React.useEffect(() => {
    if (isReady) {
      cache.clear()
      get();
    }
  }, [cache, get, isReady])

  const {data: items = []} = res;

  const placeholder = _.cond([
    [_.constant(loading), _.constant('Cargando')],
    [_.constant(!!error), _.constant('Error conectando con el servidor')],
    [_.stubTrue, _.constant('Selecciona')],
  ])(null)

  const options = items.map((c: any) => ({
    label: c[labelField],
    value: c.id,
    re: c
  }))
  
  return (
    <Select
      {...props}
      isLoading={loading}
      isDisabled={!isReady}
      placeholder={placeholder}
      name={name}
      group={props.group}
      options={options}
    />
  );
}

export default AjaxFilter;
