import produce from "immer"

const FILTER_PROJECTS = 'PROJECTS/FILTER_PROJECTS';
const SET_NUMBER_PAGES = 'PROJECTS/SET_NUMBER_PAGES';

interface ProjectTableState {
  name?: string,
  status?: string,
  activePage: number,
  numberPages: number
}

export const initialState: ProjectTableState = {
  name: '',
  status: '',
  activePage: 1,
  numberPages: 1
}


interface FilterAction {
  type: typeof FILTER_PROJECTS,
  payload: {
    name?: string,
    status?: string,
  }
}

interface SetNumberPagesAction {
  type: typeof SET_NUMBER_PAGES,
  payload: {
    numberPages: number,
  }
}

type ProjectTableActions = FilterAction | SetNumberPagesAction;

export function projectTableReducer(state = initialState, action: ProjectTableActions) {
  if (action.type === FILTER_PROJECTS) {
    return produce(state, draft => {
      draft.status = action.payload.status;
      draft.name = action.payload.name;
      draft.activePage = 1;
    })
  }
  if (action.type === SET_NUMBER_PAGES) {
    return produce(state, draft => {
      draft.numberPages = action.payload.numberPages;
    });
  }
  return state;
}

export function filterProjectTable({name, status} : {
  name?: string,
  status?: string
}): FilterAction {
  return {
    type: FILTER_PROJECTS,
    payload: {
      name,
      status
    }
  }
}
