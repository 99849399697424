import { Field } from 'formik';
import * as React from 'react';
import { CloseButton, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import useFetch from 'use-http';
import Resizer from "react-image-file-resizer";


export interface UploadFileFieldProps {
  name: string;
  url: string;
}

const resizeFile = (file: Blob): Promise<Blob> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1000,
      1000,
      "JPEG",
      100,
      0,
      (newBlob) => {
        resolve((newBlob as Blob));
      },
      "blob"
    );
  });

const UploadFileField: React.SFC<UploadFileFieldProps> = (props: UploadFileFieldProps) => {
  const inputFileRef = React.createRef<HTMLInputElement>();
  const [uploading, setUploading] = React.useState(false);
  const { post, response } = useFetch(props.url, {
    headers: {
      Accept: 'application/json',
      'X-Language': 'ES',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
  });

  const handleChangeFile =
    async (setFieldFn: Function) => {
      // @ts-ignore
      const image = inputFileRef?.current.files[0];
      var pattern = /image-*/;
      if (!image.type.match(pattern)) {
        Swal.fire({
          icon: 'error',
          text: 'El archivo seleccionado no es una imágen'
        })
        return;
      }
      const fd = new FormData();
      
      fd.append('file', await resizeFile(image))
      setUploading(true)
      await post(fd);
      setUploading(false)
      if (!response.ok) {
        Swal.fire({
          icon: 'error',
          text: 'Error subiendo la imagen'
        })
      }
      
      
      // @ts-ignore
      setFieldFn(props.name, response.data.data.url);
    }

  return (
    <>
      <Field {...props}>
        {({ field, form, meta }: any) => (
          <>
            <input
              accept="image/*"
              type="file"
              onChange={() => handleChangeFile(form.setFieldValue)}
              ref={inputFileRef}
              style={{
                display: 'none'
              }}
            />
            <div className={`d-flex justify-content-between align-items-center ${meta.error && 'is-invalid'}`} style={{
              border: `1px solid ${meta.error ? 'red' : '#E4E6EF'}`,
              paddingLeft: 10,
              borderRadius: 7
            }}>
              <div>
                Seleccione
          </div>
              <button onClick={() => {
                if (inputFileRef.current) {
                  inputFileRef.current.click();
                }
              }} type="button" className="btn btn-primary"
              disabled={uploading}
              style={{
                backgroundColor: '#F15E3D',
                border: 'none',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}>{uploading ? 'Cargando...' : 'Explorar'}</button>
            </div>

            {field.value && (
              <div style={{
                position: 'relative'
              }} className="mt-2">
                <CloseButton onClick={() => {
                  form.setFieldValue(field.name, '')
                }}></CloseButton>
                <img alt="Previsualización de la imágen" src={field.value} style={{
                  minHeight: 100,
                  width: '100%',
                  marginTop: 20
                }} />
              </div>
            )}
            <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
          </>
        )}
      </Field>
    </>
  );
}

export default UploadFileField;