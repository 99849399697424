
import * as React from 'react';
import { Card, Col } from 'react-bootstrap';
import { useParams } from 'react-router';
import useFetch from 'use-http';
import CommercialActivityEditWizzard from '../components/CommercialActivityEditWizzard';
import NewCActivityWizard from '../components/NewCActivityWizard';

export interface CActivitiesEditPageProps {
  
}
 
const CActivitiesEditPage: React.FunctionComponent<CActivitiesEditPageProps> = (props: CActivitiesEditPageProps) => {
  return (
    <div className="row">
          <Col>
            <Card>
                <Card.Body>
                    <CommercialActivityEditWizzard></CommercialActivityEditWizzard>
                </Card.Body>
            </Card>
          </Col>
      </div>
  );
}
 
export default CActivitiesEditPage;