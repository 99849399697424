import * as React from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ExportExcelButton } from "../../Tables/ExportExcelButton";
import { BaseBadge } from "../../ui/Badge";
import { InfoProgressBar } from "../../ui/ProgressBar";
import { FollowCommercialData } from "../entities/followCommercialRequest";
import { selectCommercialData } from "../_redux/followCommercial/FollowCommercialSelector";

export interface FollowActionTableProps {}

const FollowActionTable: React.SFC<FollowActionTableProps> = (
  props: FollowActionTableProps
) => {
  const commercialData: FollowCommercialData = useSelector(
    selectCommercialData
  );
  const actions = commercialData.project_comercial_actions || [];
  return (
    <>
      <ExportExcelButton
        csvData={actions.map(co => ({
          id: co.id,
          nombre: co.name,
          tipo: co.action_type.name,
          descripcion: co.description,
          pdvs: co.pdvs,
          impactados: co.impacted_pdvs,
          estado: co.status.name,
          efectividad: `${(co.impacted_pdvs * 100) / co.pdvs} %`
        }))}
        fileName="reporte.csv"
      ></ExportExcelButton>
      <Table responsive className="text-center">
        <thead>
          <tr>
            <th>ID</th>
            <th>NOMBRE</th>
            <th>TIPO</th>
            <th>DESCRIPCIÓN</th>
            <th>PDVs</th>
            <th>PDVs Efectivos</th>
            <th>EFECTIVIDAD</th>
            <th>ESTADO</th>
          </tr>
        </thead>
        <tbody>
          {actions.map(co => (
            <tr key={String(co.id)}>
              <td>{co.id}</td>
              <td>{co.name}</td>
              <td>{co.action_type.name}</td>
              <td
                style={{
                  maxWidth: 200
                }}
              >
                {co.description}
              </td>
              <td>{co.pdvs}</td>
              <td>{co.impacted_pdvs}</td>
              <td
                style={{
                  width: 200
                }}
              >
                <InfoProgressBar
                  withExplanation
                  total={co.pdvs}
                  value={co.impacted_pdvs}
                ></InfoProgressBar>
              </td>
              <td
                style={{
                  maxWidth: 100
                }}
              >
                <BaseBadge
                  small
                  color={co.status.id === 8 ? "#139345" : "#137E95"}
                  background={co.status.id === 8 ? "#AEECCA" : "#BFF0FB"}
                >
                  {co.status.name}
                </BaseBadge>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default FollowActionTable;
