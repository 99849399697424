import { Divider } from '@material-ui/core';
import {  useFormikContext } from 'formik';
import _ from 'lodash';
import { useFormikWizard } from 'formik-wizard';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import './ActivitySummary.scss';
import useFetch from 'use-http';
import { useSelector } from 'react-redux';
import defaultFilters from './defaultFilters';
import UsdToCopValue from '../../../components/UsdToCopValue';

export interface ActivitySummaryProps {
  
}

let copLocale = Intl.NumberFormat('es-CO', {
  style: 'currency',
  maximumFractionDigits: 0,
  currency: 'COP'
});

let usdLocale = Intl.NumberFormat('en-US', {
  style: 'currency',
  maximumFractionDigits: 2,
  currency: 'USD'
});



const ActivitySummary: React.SFC<ActivitySummaryProps> = (props: ActivitySummaryProps) => {
  const {values: formikValues} = useFormikContext<any>();
  const {values: wValues} = useFormikWizard<any>();
  const {request, response, cache} = useFetch(`${process.env.REACT_APP_API_HOST}/api/v2/meiko/pdv/total`)
  const user = useSelector((state: any) => state.auth.user);
  const [totals, setTotals] = React.useState<any>({});
  const {post: calculateValue, response: calculateResult} = useFetch(`${process.env.REACT_APP_API_HOST}/api/meiko/calculate/cost`)


  function getLabel(key: string) {
    if (_.has(formikValues, key)) {
      return formikValues[key];
    }
    if (_.has(wValues.basic, key) && wValues.basic[key]) {
        return wValues.basic[key];
    }
    if (_.has(wValues.advanced, key) && wValues.advanced[key]) {
        return wValues.advanced[key];
    }
    if (_.has(wValues.strategic, key) && wValues.strategic[key]) {
        return wValues.strategic[key];
    }
    
  }

  function count_filters(raw: any) {
    const total = {
      "basics": 0,
      "advanced": 0,
      "strategic": 0,
    }

    if (raw.cities.length > 0) {
      total.basics += 1;
    }

    if (raw?.circuits_array?.length > 0) {
      total.basics += 1;
    }
    if (raw?.channels?.length > 0) {
      total.basics += 1;
    }

    if (raw?.localities_array?.length > 0) {
      total.advanced += 1;
    }
    if (raw?.nse?.length > 0) {
      total.advanced += 1;
    }
    if (raw?.vocation?.length > 0) {
      total.advanced += 1;
    }
    if (raw?.tipification?.length > 0) {
      total.strategic += 1;
    }
    if (raw?.declared_sales?.length > 0) {
      total.strategic += 1;
    }
    if (raw?.buffer?.length > 0) {
      total.strategic += 1;
    }
    if (raw?.categories_baskets?.length > 0) {
      total.strategic += 1;
    }
    if ((_.isSafeInteger(raw?.cooler_with?.inferior) && _.isSafeInteger(raw?.cooler_with?.superior)) ||
        (_.isSafeInteger(raw?.cooler_without?.inferior) && _.isSafeInteger(raw?.cooler_without?.superior))) {
      total.strategic += 1;
    }
    if (raw?.categories?.inferior > 0 || raw?.categories?.superior > 0) {
      total.strategic += 1;
    }
    return total
  }

  function getComposedSelectLabel(key: string) {
    let values = [];
    if (_.has(wValues.basic, key) && wValues.basic[key]) {
        values = wValues.basic[key];
    }
     if (_.has(formikValues, key) && formikValues[key]) {
        values = formikValues[key];
    }

       return values.filter((i: any) => Array.isArray(i)).map(function (element: any) {
         return element['_label']
     }).join(', ');

  }


  React.useEffect(() => {
    (async () => {
            cache.clear()
            const rawValues = {
            ...defaultFilters,
            ...wValues.action,
            ...wValues.basic,
            ...wValues.advanced,
            ...wValues.strategic,
            ...formikValues,
            "target_type_id": 13,
            };
            
            rawValues.localities = rawValues.localities_array 
            rawValues.circuits = rawValues.circuits_array 
            await request.post(rawValues)
            const total =  parseInt(response.data?.data?.total_super_vecino)
            if (rawValues.comercial_action_type_id) {
              await calculateValue({
                ...count_filters(rawValues),
                cant_pdv: total,
                comercial_action_type_id: rawValues.comercial_action_type_id,
              })
              localStorage.setItem('calculate_result', JSON.stringify(
                _.pick(calculateResult?.data, ['total_dolares', 'total_dolares_x_pdv', 'total_pesos', 'total_pesos_x_pdv']))
              );
              localStorage.setItem('total_pesos', calculateResult?.data?.total_pesos) 
            }
            

          try {
              setTotals({
                  total,
                  total_no_inscritos: response.data.data.total_no_inscritos,
                  total_super_vecino: response.data.data.total_super_vecino,
            })
          } catch(ex) {
            // @TODO arreglar
          }
          
      })()
  }, [cache, formikValues, request, response, user, wValues])

  return (
      <div>
        {/* Count */}
        <div className="counter_container">
            <div className="text-right">
                <SVG src="/media/svg/icons/money.svg"></SVG>
            </div>
          <div className="acSummaryItem__section">Precio en pesos colombianos</div>
          <div className="d-flex">
                <div className="acSummaryItem">
                    <div className="acSummaryItem__title">
                      Super<br /> Vecino
                    </div>
                    <div className="acSummaryItem__number">
                        <SVG height={20} src="/media/svg/icons/phone.svg"></SVG>
                        <div className="ml-2">{totals.total_super_vecino}</div>
                    </div>
                </div>
                <div className="acSummaryItem">
                    <div className="acSummaryItem__title">
                      Precio<br /> Total 
                    </div>
                    <div className="acSummaryItem__number">
                        <SVG height={20} src="/media/svg/iconjs/phone.svg"></SVG>
                      <div className="ml-2">{copLocale.format(
                        (calculateResult?.data?.total_pesos || 0)
                        .toString()
                      )}</div>
                    </div>
                </div>
              <div className="acSummaryItem">
                    <div className="acSummaryItem__title">
                      Precio por PDV 
                    </div>
                    <div className="acSummaryItem__number">
                        <SVG height={20} src="/media/svg/icons/phone.svg"></SVG>
                      <div className="ml-2">{copLocale.format((calculateResult?.data?.total_pesos_x_pdv ?? 0).toString())}</div>
                    </div>
                </div>
            </div>
                    <div className="acSummaryItem__section mt-5 pt-5">Precio en dolares</div>

            <div className="d-flex">
                <div className="acSummaryItem">
                    <div className="acSummaryItem__title">
                      Super<br /> Vecino
                    </div>
                    <div className="acSummaryItem__number">
                        <SVG height={20} src="/media/svg/icons/phone.svg"></SVG>
                        <div className="ml-2">{totals.total_super_vecino}</div>
                    </div>
                </div>
                <div className="acSummaryItem">
                    <div className="acSummaryItem__title">
                      Precio<br /> Total 
                    </div>
                    <div className="acSummaryItem__number">
                        <SVG height={20} src="/media/svg/icons/phone.svg"></SVG>
                      <div className="ml-2">{usdLocale.format(
                        (calculateResult?.data?.total_dolares || 0)
                        .toString()
                      )}</div>
                    </div>
                </div>
              <div className="acSummaryItem">
                    <div className="acSummaryItem__title">
                      Precio por PDV 
                    </div>
                    <div className="acSummaryItem__number">
                        <SVG height={20} src="/media/svg/icons/phone.svg"></SVG>
                      <div className="ml-2">{usdLocale.format((calculateResult?.data?.total_dolares_x_pdv ?? 0).toString())}</div>
                    </div>
                </div>
            </div>
        </div>
        {/* end Count */}
        {/* end summary */}
        <div className="summary__container mt-5">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="summary_title">Resumen Selección</div>
                <SVG src="/media/svg/icons/paste.svg"></SVG>
            </div>
            <Row className="mt-3 py-4">
              {getLabel('country_label') && <Col xs={4} className="mb-4">
                    <div className="bold">País:</div>
                    <div>{getLabel('country_label')}</div>
                </Col>}
               
                {getLabel('cities_label') && <Col xs={4} className="mb-4">
                    <div className="bold">Ciudad:</div>
                    <div>{getLabel('cities_label')}</div>
                </Col>}

                {getComposedSelectLabel('localities') && <Col xs={4} className="mb-4">
                    <div className="bold">Localidades:</div>
                    <div>{getComposedSelectLabel('localities')}</div>
                    </Col>}

                {getLabel('channels_label') && <Col xs={4} className="mb-4">
                    <div className="bold">Canal:</div>
                    <div>{getLabel('channels_label')}</div>
                </Col>}
                {getComposedSelectLabel('fil_basic_circuits') && <Col xs={4} className="mb-4">
                    <div className="bold">Circuitos:</div>
                    <div>{getComposedSelectLabel('fil_basic_circuits')}</div>
                    </Col>}

                {getLabel('fil_basic_locality_label') && <Col xs={4} className="mb-4">
                    <div className="bold">Localidad:</div>
                    <div>{getLabel('fil_basic_locality_label')}</div>
                </Col>}
                {getLabel('nse_label') && <Col xs={4} className="mb-4">
                    <div className="bold">NSE:</div>
                    <div>{getLabel('nse_label')}</div>
                </Col>}
                {getLabel('fil_advanced_road_type_label') && <Col xs={4} className="mb-4">
                    <div className="bold">Tipo de Vía:</div>
                    <div>{getLabel('fil_advanced_road_type_label')}</div>
                </Col>}
                {getLabel('fil_advanced_seller_area_label') && <Col xs={8} className="mb-4">
                    <div className="bold">Zona del Vendedor:</div>
                    <div>{getLabel('fil_advanced_seller_area_label')}</div>
                </Col>}
                {getLabel('fil_advanced_property_type_label') && <Col xs={8} className="mb-4">
                    <div className="bold">Tipo de Propiedad::</div>
                    <div>{getLabel('fil_advanced_property_type_label')}</div>
                </Col>}
                {getLabel('vocation_label') && <Col xs={8} className="mb-4">
                    <div className="bold">Vocación General:</div>
                    <div>{getLabel('vocation_label')}</div>
                </Col>}
                {getLabel('payment_metods') && <Col xs={8} className="mb-4">
                    <div className="bold">Medios de Pago:</div>
                    <div>{getLabel('payment_metods') ? 'Si' : 'No'}</div>
                </Col>}
                {getLabel('domiciliary') && <Col xs={8} className="mb-4">
                    <div className="bold">Domicilios:</div>
                    <div>{getLabel('domiciliary') ? 'Si' : 'No'}</div>
                </Col>}
                {getLabel('fil_advanced_tipification_label') && <Col xs={4} className="mb-4">
                    <div className="bold">Tipificación Meiko:</div>
                    <div>{getLabel('fil_advanced_tipification_label')}</div>
                </Col>}
                {getLabel('fil_advanced_tipification_client_label') && <Col xs={8} className="mb-4">
                    <div className="bold">Tipificación Meiko + Cliente:</div>
                    <div>{getLabel('fil_advanced_tipification_client_label')}</div>
                </Col>}
                {getLabel('fil_advanced_channel_client_seg_label') && <Col xs={8} className="mb-4">
                    <div className="bold">Segmentación Cliente - Canal:</div>
                    <div>{getLabel('fil_advanced_channel_client_seg_label')}</div>
                </Col>}
                {getLabel('fil_basic_category_label') && <Col xs={8} className="mb-4">
                    <div className="bold">Categoria:</div>
                    <div>{getLabel('fil_basic_category_label')}</div>
                </Col>}
                {getLabel('fil_basic_brand_label') && <Col xs={8} className="mb-4">
                    <div className="bold">Marca:</div>
                    <div>{getLabel('fil_basic_brand_label')}</div>
                </Col>}
                {getLabel('fil_basic_segment_label') && <Col xs={8} className="mb-4">
                    <div className="bold">Segmento:</div>
                    <div>{getLabel('fil_basic_segment_label')}</div>
                </Col>}
                {getLabel('coverage') && <Col xs={8} className="mb-4">
                    <div className="bold">Cobertura:</div>
                    <div>{getLabel('coverage') ? 'Si' : 'No'}</div>
                </Col>}
                {getLabel('exhausted') && <Col xs={8} className="mb-4">
                    <div className="bold">Agotados:</div>
                    <div>{getLabel('exhausted') ? 'Si' : 'No'}</div>
                </Col>}
                {getLabel('exhausted') && <Col xs={8} className="mb-4">
                    <div className="bold">Agotados:</div>
                    <div>{getLabel('exhausted') ? 'Si' : 'No'}</div>
                </Col>}
                {getLabel('fridge') && <Col xs={8} className="mb-4">
                    <div className="bold">Neveras:</div>
                    <div>{getLabel('fridge') ? 'Si' : 'No'}</div>
                </Col>}
                {getLabel('faces') && <Col xs={8} className="mb-4">
                    <div className="bold">Caras:</div>
                    <div>{getLabel('faces') ? 'Si' : 'No'}</div>
                </Col>}
                {getLabel('rate') && <Col xs={8} className="mb-4">
                    <div className="bold">Tasa:</div>
                    <div>{getLabel('rate') ? 'Si' : 'No'}</div>
                </Col>}
                {getLabel('pop_material') && <Col xs={8} className="mb-4">
                    <div className="bold">Material POP / Exhibiciones Especiales:</div>
                    <div>{getLabel('pop_material') ? 'Si' : 'No'}</div>
                </Col>}
            </Row>
            <Divider></Divider>
            {/* <PdvMapButton></PdvMapButton> */}
        </div>
        {/* end summary */}
    </div>
  );
}
 
export default ActivitySummary;
