import { Field } from 'formik';
import * as React from 'react';
import { Col, Form, FormGroup, FormLabel, Row } from 'react-bootstrap';
import FormikBootstrapInput from '../../ui/FormikBootstrapInput';
import EditorField from './EditorField';
import UploadFileField from './UploadFileField';

export interface CommercialActivitiesEditPromoFieldsProps {
  
}
 
const CommercialActivitiesEditPromoFields: React.FunctionComponent<CommercialActivitiesEditPromoFieldsProps> = (props: CommercialActivitiesEditPromoFieldsProps) => {
  return (
    <div>
      <Row>
        <Col>
          <FormGroup>
            <FormLabel>Nombre de la promoción:</FormLabel>
            <FormikBootstrapInput name="promotion.name" autocomplete="promo_name"></FormikBootstrapInput>
          </FormGroup>
        </Col>
      </Row>
      {/*
      <Row>
        <Col>
          <FormGroup>
            <FormLabel>Inicio de la promoción:</FormLabel>
            <FormikBootstrapInput type="datetime-local" name="promotion.start"></FormikBootstrapInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <FormLabel>Fin de la promoción:</FormLabel>
            <FormikBootstrapInput type="datetime-local" name="promotion.end"></FormikBootstrapInput>
          </FormGroup>
        </Col>
        </Row>
        */}
      <Row>
        <Col md="6">
          <FormGroup>
            <Form.Label>Imágen:</Form.Label>
            <UploadFileField url={`${process.env.REACT_APP_API_HOST}/api/comercial/action/image/save`} name="promotion.image"></UploadFileField>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <FormLabel>Descripción de la promoción:</FormLabel>
            <Field component={EditorField} name="promotion.detail"></Field>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}
 
export default CommercialActivitiesEditPromoFields;
