import { Field, useFormikContext } from 'formik';
import * as React from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import FormikBootstrapInput from '../../../../ui/FormikBootstrapInput';
import AjaxFilter from '../../../components/AjaxFilter';
import QuestionOptions from '../../../components/QuestionOptions';
import _ from 'lodash';
import { QuestionTypes } from '../entities/QuestionTypes';

export interface QuestionListItemProps {
  index: number,
  onRemoveItem: (index: number) => void
}
 
const QuestionListItem: React.FunctionComponent<QuestionListItemProps> = ({index, onRemoveItem}: QuestionListItemProps) => {
  const {values} = useFormikContext<any>();
  const questionType = _.get(values, `campaign.questions[${index}].campaign_question_type_id`)
  return (
      <Row className="mt-3">
        <Col>
          <Card>
            <div
              className="d-flex align-items-center justify-content-center py-4 text-center"
              style={{
                cursor: 'move',
                backgroundColor: '#ECF1F7',
              }}>
              <SVG src="/media/svg/icons/drag.svg"></SVG>
            </div>
            <Card.Body>
              <Row>
                <Form.Group as={Col} md="6" controlId="formBasicEmail">
                  <Form.Label>Pregunta {index + 1}:</Form.Label>
                  <FormikBootstrapInput as="textarea" rows={4} name={`campaign.questions[${index}].name`} />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicEmail">
                  <Form.Label>Tipo:</Form.Label>
                  <AjaxFilter name={`campaign.questions[${index}].campaign_question_type_id`} labelField="name" url={`${process.env.REACT_APP_API_HOST}/api/types/question/type/list`}></AjaxFilter>
                </Form.Group>
              </Row>
              <Row>
                <Col md="12">
                  {questionType !== QuestionTypes.TEXT &&
                    <QuestionOptions
                      questionIndex={index}
                      enableGoTo={questionType === QuestionTypes.UNIQUE_RESPONSE}
                    ></QuestionOptions>}
                </Col>
                <Col md="12" className="align-items-end text-right relative">
                  <Button onClick={() => onRemoveItem(index)} variant="danger">Eliminar</Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
  )
}
 
export default React.memo(QuestionListItem);
