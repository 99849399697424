
import { Field, useFormikContext } from 'formik';
import * as React from 'react';
import { Col, Row, FormGroup, Form } from 'react-bootstrap';
import FormikBootstrapInput from '../../ui/FormikBootstrapInput';
import EditorField from './EditorField';
import UploadFileField from './UploadFileField';

export interface CommercialActivitiesEditCampaignFieldsProps {
  
}
 
const CommercialActivitiesEditNewsFields: React.FunctionComponent<CommercialActivitiesEditCampaignFieldsProps> = (props: CommercialActivitiesEditCampaignFieldsProps) => {
  const {values} = useFormikContext<any>();
  const {campaign} = values;
  console.log('values', campaign);
  

  return (
    <div>
      <Row>
        <Col>
        <FormGroup>
          <Form.Label>Nombre de la noticia:</Form.Label>
          <FormikBootstrapInput label="Nombre" name="news_message.name"></FormikBootstrapInput>
        </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Form.Label>Fecha de inicio:</Form.Label>
            <FormikBootstrapInput type="datetime-local" name="news_message.start"></FormikBootstrapInput>
          </FormGroup>
        </Col>
        <Col>
        <FormGroup>
          <Form.Label>Fecha de finalización:</Form.Label>
          <FormikBootstrapInput type="datetime-local" name="news_message.end"></FormikBootstrapInput>
        </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Form.Label>Descripción:</Form.Label>
            <Field component={EditorField} name="news_message.description"></Field>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Form.Label>Imágen de la noticia:</Form.Label>
            <UploadFileField url={`${process.env.REACT_APP_API_HOST}/api/comercial/action/image/save`} name="news_message.promotional_image"></UploadFileField>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}
 
export default CommercialActivitiesEditNewsFields;