import React from "react";
import {IntlProvider} from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/es";


import esMessages from "./messages/es";

const allMessages = {
  es: esMessages,
};

export function I18nProvider({ children }) {
  const locale = 'es';
  const messages = allMessages[locale];

  return (
    <IntlProvider locale="es" messages={messages}>
      {children}
    </IntlProvider>
  );
}
