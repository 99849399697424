import * as React from 'react';
import {Row, Button, Table, Badge, ProgressBar } from 'react-bootstrap';
import SVG from "react-inlinesvg";
import PaginationPaginationSize from '../../Navigation/components/PaginationPaginationSize';
import PaginationTables from '../../Navigation/components/PaginationTables';

export interface ComercialActionsTableProps {
  history?: any
}

const ComercialActionsTable: React.SFC<ComercialActionsTableProps> = (props: ComercialActionsTableProps) => {
  return (
    <>
      <Row>
        <Table responsive className="text-center">
          <thead>
            <tr>
              <th>NOMBRE</th>
              <th>TIPO</th>
              <th>DESCRIPCIÓN</th>
              <th>PDVs</th>
              <th>PDVs Efectivos</th>
              <th>EFECTIVIDAD</th>
              {/* <th>COSTO</th> */}
              <th>ESTADO</th>
              <th>ACCIÓN</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Lorem ipsum</td>
              <td>Promoción</td>
              <td>Lorem ipsum dolor sit amet,</td>
              <td>230</td>
              <td>30</td>
              <td>
                <ProgressBar now={18} label={`18%`} />
              </td>
              {/* <td>$2.100.000</td> */}
              <td> <Badge variant="secondary" style={{
                color: '#409cb0',
                borderColor: '#409cb0',
                backgroundColor: '#bff0fb',
              }}>Ejecutada</Badge></td>
              <td>
                <div className="d-flex align-items-center justify-content-center">
                  <Button className="ml-2" variant="success" size="sm">
                    <SVG src="/media/svg/icons/mira.svg" />
                  </Button>
                </div>
              </td>
            </tr>
            <tr>
              <td>Lorem ipsum</td>
              <td>Reto</td>
              <td>Lorem ipsum dolor sit amet,</td>
              <td>456</td>
              <td>156</td>
              <td>
                <ProgressBar now={29} label={`29%`} />
              </td>
              <td>$2.100.000</td>
              <td> <Badge variant="secondary" style={{
                color: '#269e55',
                borderColor: '#269e55',
                backgroundColor: '#aeecca',
              }}>En Ejecución</Badge></td>
              <td>
                <div className="d-flex align-items-center justify-content-center">
                  <Button className="ml-2" variant="success" size="sm">
                    <SVG src="/media/svg/icons/mira.svg" />
                  </Button>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </Row>
      <div className="d-flex justify-content-between align-items-center">
        <PaginationTables />
        <PaginationPaginationSize />
      </div>
    </>
  );
}

export default ComercialActionsTable;