import * as React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

export interface PageContainerProps {
  children: React.ReactNode
}
 
const PageContainer: React.SFC<PageContainerProps> = (props: PageContainerProps) => {
  return (
    <Container>
      <Row>
        <Col>
          <Card>{props.children}</Card>
        </Col>
      </Row>
    </Container>
  );
}
 
export default PageContainer;