import * as React from 'react';
import FormikWizard from 'formik-wizard'
import useCActivityWizardSteps from './useCActivityWizardSteps';
import SVG from 'react-inlinesvg';
import { Divider } from '@material-ui/core';
import { Button, Card, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import _ from 'lodash';
import useFetch from 'use-http';
import { getGenericHeaders } from '../../../../redux';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import defaultFilters from './defaultFilters';
import * as yup from 'yup';
import useQuery from '../../Navigation/hooks/useQuery';


export interface NewCActivityWizardProps {
  history: any 
}

function FormWrapper({
    children,
    currentStep,
    isLastStep,
    actionLabel,
    goToPreviousStep,
    canGoBack
  }: any) {
    return (
      <div>
          <div className="d-flex">
            <div className={`wizard_item ${currentStep === 'basic' && 'active'}`}>
                <div className="wizard_icon"><SVG src="/media/svg/icons/basic_filters.svg"></SVG></div>
                <div className="wizard_label">1. Filtros Básicos</div>
                <div className="arrow">
                    <SVG src="/media/svg/icons/next_arrow.svg"></SVG>
                </div>
            </div>
            <div className={`wizard_item ${currentStep === 'advanced' && 'active'}`}>
                <div className="wizard_icon"><SVG src="/media/svg/icons/advanced_filters.svg"></SVG></div>
                <div className="wizard_label">2. Avanzados</div>
            </div>
            <div className={`wizard_item ${currentStep === 'strategic' && 'active'}`}>
                <div className="wizard_icon"><SVG src="/media/svg/icons/bull.svg"></SVG></div>
                <div className="wizard_label">3. Estratégicos</div>
            </div>
            <div className={`wizard_item ${currentStep === 'action' && 'active'}`}>
                <div className="wizard_icon"><SVG src="/media/svg/icons/bull.svg"></SVG></div>
                <div className="wizard_label">4. Acción</div>
            </div>
          </div>
        <Divider className="mt-3 mb-5"></Divider>
        {children}
        <Card.Footer className="my-5 pt-4 text-right">
          <Button className="mr-2" type="button" onClick={goToPreviousStep} disabled={!canGoBack}>
            Atrás
          </Button>
          <Button type="submit">
            {actionLabel || (isLastStep ? 'Finalizar' : 'Siguiente')}
          </Button>
        </Card.Footer>
      </div>
    )
  }


  const submitSchema = yup.object().shape({
    sdlfjsl: yup.string().required()
  })

  
const NewCActivityWizard: React.SFC<NewCActivityWizardProps> = (props: NewCActivityWizardProps) => {
  const [confirmation, setVisibleConfirmation] = React.useState<any>();
  const {post, response, loading} = useFetch(`${process.env.REACT_APP_API_HOST}/api/v2/meiko/pdv/store/comercial/action`, {
    headers: getGenericHeaders(),
  });
  const query = useQuery()

  const handleSubmit = React.useCallback(async (values: any): Promise<any> => {
    try {
          console.log(await submitSchema.validate(values))

    } catch(ex){
      console.log(ex)
    }
      setVisibleConfirmation(values)
      return {
        message: 'Thanks for submitting!',
      }
  }, [])
  const user = useSelector((state: any) => state.auth.user)
  const steps = useCActivityWizardSteps() 
    
  
  return (
    <>
      <FormikWizard
          
          steps={steps}
          onSubmit={handleSubmit}
          render={FormWrapper}
      />
        <Modal centered show={confirmation} onHide={() => setVisibleConfirmation(false)}>
          <Modal.Header>
            <Modal.Title className="text-primary">Confirmar</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            ¿Estás seguro que desea crear esta acción Comercial?
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={loading} onClick={() => setVisibleConfirmation(false)}>Cancelar</Button>
            <Button disabled={loading} variant="secondary" className="ml-2" onClick={async () => {
              const calculated_values = JSON.parse(localStorage.getItem('calculate_result') || '{}');
              const raw = {
                  ...defaultFilters,
                  ...confirmation.basic,
                  ...confirmation.advanced,
                  ...confirmation.strategic,
                  ...confirmation.action,
                  ...calculated_values,
                  fil_advanced_neighborhood: [],
                  fil_basic_maker: _.get(user, 'maker.meiko_id'),
                  "target_type_id": 13,
                  "valor_pesos": localStorage.getItem('total_pesos'),
              };
              raw.localities = raw.localities_array
              try {                
                await post(raw);
                if (!response.ok) {
                  Swal.fire({
                    icon: 'error',
                    text: 'Error completando la petición'
                  })
                  return;
                }
                await Swal.fire({
                  icon: 'success',
                  text: 'Acción creada correctamente'
                })
                props.history.push(`acciones-comerciales?project=${query.get('project')}`)
              } catch(ex) {
                console.log(ex);
                
                alert('Error completando la petición')
              }
            }}>{loading ? 'Enviando' : 'Confirmar'}</Button>
          </Modal.Footer>
        </Modal>
    </>
  );
}
 
export default withRouter(NewCActivityWizard);
