import { createSelector } from "reselect";
import AppState from "../../../../entities/AppState";
import { FollowCommercialData } from "../../entities/followCommercialRequest";

export const selectCommercialData = (state: AppState): FollowCommercialData => {
  return (state.followCommercial.get("data") as FollowCommercialData);
};

export const selectIsLoading = (state: AppState): boolean => {
  return state.followCommercial.get("isLoading") as boolean;
}

export const selectCommercialWithStatus = createSelector(
  selectCommercialData,
  selectIsLoading,
  (commercialData, isLoading) => ({ commercialData, isLoading })
);
