import { ProjectFormValues } from '../components/ProjectForm';
import { getGenericHeaders } from "../../../../redux";

export interface Project {
    "name" : string,
    "description" : string,
    "start"  : string,
    "end"  :  string,
    "status_type_id": number
}

export async function createProject(data: ProjectFormValues): Promise<void> {
  const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/project`, {
    method: 'post',
    headers: getGenericHeaders(),
    body: JSON.stringify(data)
  })
  if (!res.ok) {
    if (res.status === 422) {
      const {errors = { unknown: 'Error conectando con el servidor'}} = await res.json();
      for (let errorkey in errors) {
        throw new Error(errors[errorkey]);
      }
    } else {
      throw new Error('Error conectando con el servidor');
    }
  }
}

export async function updateProject(data: ProjectFormValues): Promise<void> {
  if (!data.id) {
    throw new Error('Se debe especificar un id para actualizar');
  }
  const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/project/${data.id}`, {
    method: 'put',
    headers: getGenericHeaders(),
    body: JSON.stringify(data)
  })

  if (!res.ok) {
    if (res.status === 422) {
      const {errors = { unknown: 'Error conectando con el servidor'}} = await res.json();
      for (let errorkey in errors) {
        throw new Error(errors[errorkey]);
      }
    } else {
      throw new Error('Error conectando con el servidor');
    }
  }
}

