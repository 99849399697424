import { Field, useFormikContext } from 'formik';
import * as React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import AjaxFilter from './AjaxFilter';
import EditorField from './EditorField';
import UploadFileField from './UploadFileField';

export interface ChallengeFormProps {
  
}
 
const ChallengeForm: React.SFC<ChallengeFormProps> = (props: ChallengeFormProps) => {
  const {values} = useFormikContext<any>();
  return (
    <div>
      <Form.Group as={Row}>
        <Col>
          <Form.Label>Nombre:</Form.Label>
          <Field name="challenge.name" as={Form.Control}></Field>
        </Col>
        <Col>
          <Form.Label>Tipo de reto:</Form.Label>
          <AjaxFilter labelField="name" url={`${process.env.REACT_APP_API_HOST}/api/types/challenge/type/list`} name="challenge.challenge_type_id"></AjaxFilter>
        </Col>
      </Form.Group>
      {values?.challenge?.challenge_type_id == 39 && <Form.Group as={Row}>
        <Col>
          <Form.Label>Cantidad de fotos:</Form.Label>
          <Field name="challenge.photos_quantity" as={Form.Control}></Field>
        </Col>
      </Form.Group>}
      <Row className="mt-2">
        <Form.Group as={Col} md="6" controlId="formBasicEmail">
          <Form.Label>Fecha Inicial:</Form.Label>
          <Field as={Form.Control} name="challenge.start" type="datetime-local"></Field>
          <Form.Text className="text-muted">
            Ingrese el dato
                        </Form.Text>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="formBasicEmail">
          <Form.Label>Fecha Final:</Form.Label>
          <Field as={Form.Control} name="challenge.end" type="datetime-local"></Field>
          <Form.Text className="text-muted">
            Ingrese el dato
                        </Form.Text>
        </Form.Group>
      </Row>
      <Form.Group as={Row}>
        <Col>
          <Form.Label>Imágen promocional:</Form.Label>
          <UploadFileField url={`/api/comercial/action/image/save`} name="challenge.promotional_image"></UploadFileField>
        </Col>
        <Col>
          <Form.Label>Imágen de referencia:</Form.Label>
          <UploadFileField url={`/api/comercial/action/image/save`} name="challenge.reference_image"></UploadFileField>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col>
          <Form.Label>Descripción:</Form.Label>
          <Field component={EditorField} name="challenge.description"></Field>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col>
          <Form.Label>Instrucciones:</Form.Label>
          <Field component={EditorField} name="challenge.instructions"></Field>
        </Col>
      </Form.Group>
    </div>
  );
}
 
export default ChallengeForm;