/* eslint-disable no-restricted-imports */
import * as React from 'react';
import { Button, Card } from 'react-bootstrap';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Divider } from '@material-ui/core';
import ProjectsTable from '../modules/Project/components/ProjectsTable';
import { withRouter } from "react-router";

export interface ProjectsPageProps {
  history: any
}
 
const ProjectsPage: React.SFC<ProjectsPageProps> = (props: ProjectsPageProps) => {
    const [activeSection, setActiveSection] = React.useState<any>(0);
  return (
    <div className="row">
        <div className="col">
            <Card>
                <Card.Header className="justify-content-between d-flex align-items-center">
                    <Card.Title>Proyectos</Card.Title>
                    <div>
                        {/* <Button onClick={() => {
                            props.history.push('/nueva-accion')
                        }} variant="secondary" className="mr-2">Crear Acción comercial</Button> */}
                        <Button onClick={() => {
                            props.history.push('/nuevo-proyecto')
                        }}>Crear Proyecto</Button>
                    </div>
                </Card.Header>
                <Card.Body>
                <Tabs value={activeSection} indicatorColor="secondary" textColor="secondary" onChange={(_, nv) => setActiveSection(nv)}>
                    <Tab label="Proyectos" />
                    {/* <Tab label="Acciones Independientes" /> */}
                </Tabs>
                    <Divider></Divider>
                    <ProjectsTable></ProjectsTable>
                </Card.Body>
            </Card>
        </div>
    </div>
  );
}
 
export default withRouter(ProjectsPage);