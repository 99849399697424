import * as React from 'react';
import { Button } from 'react-bootstrap';
import { Project, updateProject } from '../store/projectCrud';

export interface ProjectStopButtonProps {
  project: Project
}

const ACTIVE_STATUS = 2;
const INACTIVE_STATUS = 3;

 
const ProjectStopButton: React.FunctionComponent<ProjectStopButtonProps> = ({project}: ProjectStopButtonProps) => {
  const [isInactive, setInactive] = React.useState(project.status_type_id === INACTIVE_STATUS);
  const [loading, setLoading] = React.useState(false);
  return (
    <Button disabled={loading} onClick={() => {
      setLoading(true);
      try {
        updateProject({
          ...project,
          status_type_id: isInactive ? ACTIVE_STATUS : INACTIVE_STATUS,
        });
        setInactive(!isInactive);
      } catch(ex) {
        toastr.error('Error completando la acción');
      }
      setLoading(false);
    }} className="ml-2" variant={isInactive ? 'info' : 'danger'} size="sm">
        <i className={loading ? 'fas fa-spinner' : (isInactive ? 'fas fa-play' : 'fas fa-stop')}></i>
    </Button>
  );
}
 
export default React.memo(ProjectStopButton);