import { Divider } from '@material-ui/core';
import * as React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import ActivitySummary from './ActivitySummary';
import CActivityLabel from './CActivityLabel';
import AjaxFilter from './AjaxFilter';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Checkbox } from '../../../../_metronic/_partials/controls';
import FormikBootstrapInput from '../../ui/FormikBootstrapInput';


export interface StrategicProps {

}

const Strategic: React.SFC<StrategicProps> = (props: StrategicProps) => {
  const {values = {}, ...context}: any = useFormikContext();
  const {fil_basic_category, fil_basic_maker, fil_basic_brand} = (values as any);

  React.useEffect(() => {
    context.touched.cooler_with = {
      inferior: true,
      superior: true
    }
    context.touched.cooler_without = {
      inferior: true,
      superior: true
    }
  }, [])

  return (
    <div>
      <div className="section_title">Filtros Estratégicos</div>
      <Row>
        {/* form */}
        <Col xs="12" md="8">
          <Row className="justify-content-between">
            <CActivityLabel></CActivityLabel>
            {/* <SaveFilterBtn></SaveFilterBtn> */}
          </Row>

          <Row>

            <Divider className="my-5 w-100"></Divider>
            <Form.Group as={Col} md="12">
              <Form.Label>Tipificación Meiko:</Form.Label>
              <AjaxFilter
                isMulti
                name="tipification"
      url={`${process.env.REACT_APP_API_HOST}/api/v2/meiko/tipifications/list`}
              ></AjaxFilter>
              <Form.Text className="text-muted">
                Ingrese el dato
              </Form.Text>
            </Form.Group>
            <Col md="6" className="mb-5">
              <Form.Label>Ventas declaradas</Form.Label>
              <FieldArray
                name="declared_sales"
                render={arrayHelpers => (
                  <div>
      {values.declared_sales && values.declared_sales.length > 0 ? (
                      values.declared_sales.map((ds: any, index: number) => (
                        <div key={index} className="my-2">
                          <Row className="mb-2">
                            <Col>
                              <FormikBootstrapInput autotouch name={`declared_sales[${index}]['inferior']`} placeholder="mínimo"></FormikBootstrapInput>
                            </Col>
                            <Col>
                              <FormikBootstrapInput autotouch name={`declared_sales[${index}]['superior']`} placeholder="máximo"></FormikBootstrapInput>
                            </Col>
                          </Row>


                          <button
                            className="btn btn-danger mr-2"
                            type="button"
                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                          >
                            - Borrar filtro
                          </button>
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                          >
                            + Agregar filtro
                          </button>
                        </div>
                      ))
                    ) : (
                      <button className="btn btn-primary" type="button" onClick={() => arrayHelpers.push('')}>
                        {/* show this when user has removed all friends from the list */}
                        Filtrar por ventas declaradas
                      </button>
                    )}
                  </div>
                )}
              />

            </Col>
          </Row>
          <Form.Label>Neveras marcadas</Form.Label>

          <Row className="mb-2">
            <Col>
              <FormikBootstrapInput type="number" name={`cooler_with.inferior`} placeholder="mínimo"></FormikBootstrapInput>
            </Col>
            <Col>
              <FormikBootstrapInput type="number" name={`cooler_with['superior']`} placeholder="máximo"></FormikBootstrapInput>
            </Col>
          </Row>
          <Form.Label>Neveras sin marcar</Form.Label>

          <Row className="mb-5">
            <Col>
              <FormikBootstrapInput type="number" name={`cooler_without['inferior']`} placeholder="mínimo"></FormikBootstrapInput>
            </Col>
            <Col>
              <FormikBootstrapInput type="number" name={`cooler_without['superior']`} placeholder="máximo"></FormikBootstrapInput>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col>
              <Form.Label>Buffer</Form.Label>
              <div className="w-100">
                <FieldArray
                  name="buffer"
                  render={arrayHelpers => (
                    <div>
                      {values.buffer && values.buffer.length > 0 ? (
                        values.buffer.map((ds: any, index: number) => (
                          <div key={index} className="my-2">
                            <Row className="mb-2">
                              <Col>
                                <FormikBootstrapInput as="select" name={`buffer[${index}]['tipo']`} placeholder="Tipo">
                                  <option value="">Seleccione</option>
                                  <option value="JyB">JyB</option>
                                  <option value="D1">D1</option>
                                  <option value="Ara">Ara</option>
                                </FormikBootstrapInput>
                              </Col>
                              <Col>
                                <FormikBootstrapInput as="select" name={`buffer[${index}]['radio']`} placeholder="Radio">
                                  <option value="">Seleccione</option>
                                  <option value="100">100</option>
                                  <option value="200">200</option>
                                  <option value="300">300</option>
                                  <option value="400">400</option>
                                  <option value="500">500</option>
                                </FormikBootstrapInput>
                              </Col>
                            </Row>


                            <button
                              className="btn btn-danger mr-2"
                              type="button"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                              - Borrar filtro
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                            >
                              + Agregar filtro
                            </button>
                          </div>
                        ))
                      ) : (
                        <button className="btn btn-primary" type="button" onClick={() => arrayHelpers.push('')}>
                          {/* show this when user has removed all friends from the list */}
                          Filtrar por buffer
                        </button>
                      )}
                    </div>
                  )}
                />
              </div>
            </Col>

          </Row>


          <Form.Label>Categorías</Form.Label>

          <Row className="mb-5">
            <Col>
              <FormikBootstrapInput autotouch name={`categories['inferior']`} placeholder="mínimo"></FormikBootstrapInput>
            </Col>
            <Col>
              <FormikBootstrapInput autotouch name={`categories['superior']`} placeholder="máximo"></FormikBootstrapInput>
            </Col>
          </Row>

          <Form.Label>Categorías por canasta</Form.Label>
          <FieldArray
            name="categories_baskets"
            render={arrayHelpers => (
              <div className="mb-5">
                {values.categories_baskets && values.categories_baskets.length > 0 ? (
                  values.categories_baskets.map((ds: any, index: number) => (
                    <div key={index} className="my-2">
                      <Row className="mb-2">
                        <Col>
                          <AjaxFilter
                            name={`categories_baskets.${index}.tipo`}
                            url={`${process.env.REACT_APP_API_HOST}/api/v2/meiko/baskets/list`}
                          ></AjaxFilter>
                        </Col>
                        <Col>
                          <FormikBootstrapInput autotouch name={`categories_baskets[${index}]['inferior']`} placeholder="Mínimo">
                          </FormikBootstrapInput>
                        </Col>
                        <Col>
                          <FormikBootstrapInput autotouch name={`categories_baskets[${index}]['superior']`} placeholder="Máximo">
                          </FormikBootstrapInput>
                        </Col>
                      </Row>


                      <button
                        className="btn btn-danger mr-2"
                        type="button"
                        onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                      >
                        - Borrar filtro
                      </button>
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                      >
                        + Agregar filtro
                      </button>
                    </div>
                  ))
                ) : (
                  <button className="btn btn-primary" type="button" onClick={() => arrayHelpers.push('')}>
                    {/* show this when user has removed all friends from the list */}
                    Filtrar por canasta
                  </button>
                )}
              </div>
            )}
          />





    </Col>
    {/* End form*/}
    <Col xs="12" md="4">
      <ActivitySummary></ActivitySummary>
    </Col>
    </Row>
    </div>
  );
}

export default Strategic;
