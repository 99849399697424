import * as React from 'react';

export interface ColorCardProps {
  children: React.ReactNode,
  color: string;
}
 
const ColorCard: React.SFC<ColorCardProps> = (props: ColorCardProps) => {
  return (
    <div style={{
      backgroundColor: props.color,
      padding: '5px 25px',
      width: '100%',
      display: 'flex',
      borderRadius: 7,
      justifyContent: 'center',
      flexDirection: 'column',
      height: '100%',
    }}>
      {props.children}
    </div>  
  );
}
 
export default ColorCard;