const defaultFilters = {
country: '',
         cities: [],
         channels: [],
         localities: [],
         circuits: [],
         nse: [],
         vocation: [],
         tipification: [],
         declared_sales: [],
         cooler_with: {
           inferior: "2",
           superior: ""
         },
         cooler_without: {
           inferior: "",
           superior: ""

         },
         buffer: [],
         categories: [],

         categories_baskets: []
}


export default defaultFilters
