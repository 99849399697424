import * as React from 'react';
import { Coordinate } from '../entities/Coordinate';



const DEFAULT_COORDS: Coordinate = {
  lat: 4.624335,
  lng: -74.063644
};

export default function usePosition() {
  const [isLoading, setLoading] = React.useState(false);
  const [location, setLocation] = React.useState<Coordinate>(DEFAULT_COORDS);

  React.useEffect(() => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        })
      });
    } else {
      setLocation(DEFAULT_COORDS)
    }
    setLoading(false);
  }, [])

  return React.useMemo(() => ({
    location,
    isLoading
  }), [
    isLoading,
    location
  ])
}