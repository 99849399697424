import * as React from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Loading from '../../ui/Loading';
import { selectCommercialData, selectIsLoading } from '../_redux/followCommercial/FollowCommercialSelector';
import FollowActionInfo from './FollowActionInfo';
import FollowActionTable from './FollowActionTable';

export interface FollowProjectInfoProps {  
}

const FollowProjectInfo: React.SFC<FollowProjectInfoProps> = (props: FollowProjectInfoProps) => {
  const commercialData = useSelector(selectCommercialData);
  const isLoading = useSelector(selectIsLoading);
  
  return (
    <Card>
      <Card.Header>
        <Card.Title>Proyecto: {commercialData?.base?.project?.name}</Card.Title>
      </Card.Header>
      <Card.Body>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <FollowActionInfo />
            <div className="mt-5">
              <FollowActionTable />
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
}

export default FollowProjectInfo;