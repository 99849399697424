import {useFormikContext} from 'formik';
import * as React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import CountrySelect from '../../Filters/components/CountrySelect';
import StoreChanelSelect from '../../Filters/components/StoreChanelSelect';
import FormikBootstrapInput from '../../ui/FormikBootstrapInput';
import AjaxFilter from './AjaxFilter';
import CitiesSelect from './CitiesSelect';

export default function BasicFilterFields() {
  const context = useFormikContext<any>()
  const {cities = [], cities_label} = context.values;
  const cityLabels = cities_label?.split(', ');
  return <Row>
    <Form.Group as={Col} md="6">
      <Form.Label>Tipo de acción comercial*</Form.Label>
      <AjaxFilter
        name={`comercial_action_type_id`}
        labelField="name"
        url={`${process.env.REACT_APP_API_HOST}/api/types/comercial/type/list`}
      />
    </Form.Group>
    <Form.Group as={Col} md="6" controlId="formBasicEmail">
      <Form.Label>Nombre de la acción *:</Form.Label>
      <FormikBootstrapInput as={Form.Control} name="name"></FormikBootstrapInput>
      <Form.Text className="text-muted">
        Ingrese el dato
      </Form.Text>
    </Form.Group>

    <Form.Group as={Col} md="12" controlId="formBasicEmail">
      <Form.Label>Descripción Corta *:</Form.Label>
      <FormikBootstrapInput as={Form.Control} name="description"></FormikBootstrapInput>
      <Form.Text className="text-muted">
        Ingrese el dato
      </Form.Text>
    </Form.Group>
    <Form.Group as={Col} md="6">
      <Form.Label>Ciudades</Form.Label>
      <CitiesSelect
        name="cities"
        country={'170'}
        watch={[]}
      />
    </Form.Group>
    <Form.Group as={Col} md="6">
      <Form.Label>Canales</Form.Label>
      <StoreChanelSelect
        name="channels"
        country={'170'}
      />
    </Form.Group>
    {cities.map((city: any, index: number) =>
    <>
      <Form.Group as={Col} md="6">
        <Form.Label>Circuitos ({cityLabels[index]})</Form.Label>
        <AjaxFilter
          name={`fil_basic_circuits[${index}]`}
          onChange={function onChange(options: any) {
            const opts = options == null ? [] : Array.isArray(options) ? options : [options];
            const circuits_array = context.values.circuits_array || [];
            if (opts.length > 0) {
              opts.forEach((opt: any) => {
                context.setFieldValue('circuits_array', [
                  ...circuits_array,
                  { id: opt.value, municipio_id: city }
                ])
              });
            } else {
              context.setFieldValue('circuits_array', [])
            }
          }}
          isMulti
          url={`/api/v2/meiko/circuits/list/170/${city}`}
        />
      </Form.Group>
    </>
               )}


  </Row>;
}
