import * as React from 'react';
import ProgressBarBase, { ProgressBarBaseProps } from './ProgressBarBase';

export interface WarnProgressBarProps extends Omit<ProgressBarBaseProps, 'color'> {
  
}
 
const WarnProgressBar: React.SFC<WarnProgressBarProps> = (props: WarnProgressBarProps) => {
  return (
    <ProgressBarBase
      {...props}
      color="#FF9300"
    />
  );
}
 
export default WarnProgressBar;