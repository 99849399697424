import * as React from 'react';
import { useFetch } from 'use-http';
import cond from 'lodash/cond'
import stubTrue from 'lodash/stubTrue'
import constant from 'lodash/constant'
import defaultTo from 'lodash/defaultTo'
import {DbType} from '../_redux/setupDbCrud'
import { GET_DB_TYPES_URL } from '../_redux/setupDbCrud';
import { getGenericHeaders } from '../../../../redux';
import {Select} from '../../../../_metronic/_partials/controls/forms/Select'

export interface DbSelectProps {
  name: string;
  disabled?: boolean;
}

const DbSelect: React.SFC<DbSelectProps> = (props: DbSelectProps) => {
  const { loading, data = {}, error } = useFetch<{ data?: DbType[] }>(GET_DB_TYPES_URL, {
    headers: getGenericHeaders()
  }, [])
  
  const items = defaultTo(data.data, []).map(d => ({ label: d.name, value: d.id}))
  const placeholder: string = cond([
    [constant(loading), constant('Cargando...')],
    [constant(!!error), constant('Error conectando con el servidor')],
    [stubTrue, constant('Seleccione')],
  ])(0)

  return (
    <Select
      placeholder={placeholder}
      customFeedbackLabel=""
      options={items}
      name={props.name}
      isDisabled={props.disabled}
    />
    
  );
}

export default DbSelect;