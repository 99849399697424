import * as React from 'react';
import useFetch from 'use-http';
import { getGenericHeaders } from '../../../../redux';
import { Select } from '../../../../_metronic/_partials/controls';
import _ from 'lodash';
import {useGetCitiesQuery} from '../../../services/miqApi';

export interface CitiesSelectProps {
  name: string,
  country: string,
  watch: any[],
}

const CitiesSelect: React.SFC<CitiesSelectProps> = (props: CitiesSelectProps) => {
  const { data: res = {}, isLoading: loading, error} = useGetCitiesQuery(props.country) 
  const {data: cities = []} = res;

  const placeholder = _.cond([
    [_.constant(loading), _.constant('Cargando')],
    [_.constant(!!error), _.constant('Error conectando con el servidor')],
    [_.constant(!props.country), _.constant('Selecciona un país')],
    [_.stubTrue, _.constant('Selecciona')],
  ])(null)

  const options = cities.map((c: any) => ({
    label: c.descripcion,
    value: c.id
  }))
  return (
    <Select
      isMulti
      isDisabled={!props.country}
      placeholder={placeholder}
      name={props.name}
      options={options}
    />
  );
}

export default CitiesSelect;
