import * as React from 'react';
import { Breadcrumb, Card } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router';
import useFetch from 'use-http';
import PageContainer from '../../../pages/PageContainer';
import usePosition from '../../location/hooks/usePosition';
import useQuery from '../../Navigation/hooks/useQuery';
import Loading from '../../ui/Loading';
import PdvMap, { MapBounds } from '../components/PdvMap';

export interface PdvMapPageProps {
  
}
 
const PdvMapPage: React.SFC<PdvMapPageProps> = (props: PdvMapPageProps) => {
  const query = useQuery();
  const [bounds, setBounds] = React.useState<MapBounds | null>(null)
  const {isLoading, location: userPosition} = usePosition()
  
  const {data = {}, get: fetchPdvs} = useFetch(`${process.env.REACT_APP_API_HOST}/api/comercial/action/pdv/list/${query.get('id')}`);
  const {data: pdvs = []} = data;
  const location = useLocation()
  const history = useHistory()

  React.useEffect(() => {
    if (bounds !== null) {
      fetchPdvs(`?lat1=${bounds.ne.lat}&lat2=${bounds.sw.lat}&lon1=${bounds.ne.lng}&lon2=${bounds.sw.lng}`);
    }
  }, [bounds, fetchPdvs])

  if (isLoading) {
    return <Loading></Loading>
  }

  return (
    <PageContainer>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.goBack()}>Acciones comerciales</Breadcrumb.Item>
        <Breadcrumb.Item href={location.pathname} active>Establecimientos Efectivos</Breadcrumb.Item>
      </Breadcrumb>
      <Card.Header>
        <Card.Title>Establecimientos Efectivos</Card.Title>
      </Card.Header>
      <Card.Body>
        <PdvMap
          pdvs={pdvs}
          setBounds={setBounds}
          defaultCenter={userPosition}
        ></PdvMap>
      </Card.Body>
    </PageContainer>
  );
}
 
export default PdvMapPage;