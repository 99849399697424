import * as React from "react";
import { Col, Row, Card } from "react-bootstrap";
import ProjectForm, { ProjectFormValues } from "../components/ProjectForm";
import toastr from "toastr";
import { createProject } from "../store/projectCrud";
import { useHistory } from "react-router";

export interface NewProjectPageProps {}

const NewProjectPage: React.SFC<NewProjectPageProps> = (
  props: NewProjectPageProps
) => {
  const history = useHistory();
  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Card.Title>Crear Proyecto</Card.Title>
          </Card.Header>
          <ProjectForm
            onSubmit={async (values: ProjectFormValues) => {
            try {
                await createProject(values);
                toastr.success('Proyecto creado correctamente')
                history.push("/proyectos");
              } catch (ex) {
                toastr.error(ex.message)
              }
            }}
          ></ProjectForm>
        </Card>
      </Col>
    </Row>
  );
};

export default NewProjectPage;
