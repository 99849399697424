import { FollowCommercialResponse } from './../../entities/followCommercialRequest';

export async function getFollowCommercialById(actionId: string | number): Promise<FollowCommercialResponse | never>  {
  const token = localStorage.getItem('token');
  const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/tracing/filter/${actionId}/detail`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  if (!res.ok) {
    throw new Error('Error conectando con el servidor');
  }
  return res.json();
}
