import * as React from 'react';
import useFetch from 'use-http';
import useQuery from '../../Navigation/hooks/useQuery';

export interface CActivityLabelProps {
  
}
 
const CActivityLabel: React.SFC<CActivityLabelProps> = (props: CActivityLabelProps) => {
  const query = useQuery()
  
  const {data = {}} = useFetch(`${process.env.REACT_APP_API_HOST}/api/project/${query.get('project')}`, {}, [query.get('project')])
  const {data: info} = data;
  
  return (
    <div className="d-flex align-items-center col" style={{ position: 'relative', color: '#333333' }}>
        <div style={{
            fontSize: 20,
            fontWeight: 'bold'
        }}>Crear Acción Comercial</div>
        <div style={{
            top: '10%',
            bottom: '10%',
            height: '80%',
            width: 1,
            backgroundColor: 'black'
        }} className="mx-4"></div>
        <div><strong>Proyecto:</strong> {info?.name}</div>
    </div>
  );
}
 
export default CActivityLabel;