import * as React from 'react';
import { Badge, Button, Col, Form, Row, Table } from 'react-bootstrap';
import PaginationPaginationSize from '../../Navigation/components/PaginationPaginationSize';
import PaginationTables from '../../Navigation/components/PaginationTables';
import ProjectStatusSelect from './ProjectStatusSelect';
import { withRouter } from 'react-router';
import useFetch from 'use-http';
import { getGenericHeaders } from '../../../../redux';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import ProjectStopButton from './ProjectStopButton';
import { projectTableReducer, initialState, filterProjectTable } from '../store/projectTableState';

export interface ProjectsTableProps {
    history: any
}

const ProjectsTable: React.SFC<ProjectsTableProps> = (props: ProjectsTableProps) => {
    const [state, dispatch] = React.useReducer(projectTableReducer, initialState);
    const {data: res={}, loading, error} = useFetch(`${process.env.REACT_APP_API_HOST}/api/project?page=${state.activePage}&pageSize=20${state.name ? `&name=${state.name}` : ''}${state.status ? `&status=${state.status}` : ''}`, {
      headers: getGenericHeaders(),
    },  [state.activePage, state.name, state.status]);
    const { data = [] } = res;

    return (
        <>
            <Formik
              initialValues={{
                status_type_id: '',
                name: ''
              }}
              onSubmit={(values) => {
                dispatch(filterProjectTable({
                  name: values.name,
                  status: values.status_type_id
                }))
              }}
            >
              {({values, handleChange, handleSubmit}) => (
                <Row className="py-5">
                <Col md="3" xs="12">
                    <Form.Control placeholder="Nombre" name="name" value={values.name} onChange={handleChange('name')}>
                    </Form.Control>
                </Col>
                <Col md="3" xs="12">
                    <ProjectStatusSelect></ProjectStatusSelect>
                </Col>
                <Col md="3" xs="12">
                    <Button onClick={() => handleSubmit() } variant="info"><i className="fas fa-filter" /> Filtrar</Button>
                </Col>
            </Row>
              )}
            </Formik>
            <Row>
                <Table responsive className="text-center">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>NOMBRE</th>
                            <th>DESCRIPCIÓN</th>
                            <th>FECHA INICIO</th>
                            <th>FECHA FIN</th>
                            <th>ESTADO</th>
                            <th>ACCIONES COMERCIALES</th>
                            <th>ACCIONES</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                          {error && <td colSpan={9}>Error conectando con el servidor</td>}
                          {!loading && !error && data.length == 0 && <td colSpan={9}>Se encuentran proyectos que coincidan con los filtros</td>}
                          {loading && <td colSpan={9}>Cargando...</td>}
                        </tr>
                        {!loading && data.map((item: any) => (
                          <tr key={item.id}>
                          {/* <td><Form.Check></Form.Check></td> */}
                          <td>{item.id}</td>
                          <td>{item.name}</td>
                          <td>{item.description}</td>
                          <td>{dayjs(item.start).format('DD MMMM YYYY')}</td>
                          <td>{dayjs(item.end).format('DD MMMM YYYY')}</td>
                          <td> <Badge variant="secondary" style={{
                              color: '#05C55C',
                              borderColor: '#05C55C',
                              backgroundColor: 'rgba(5,197,92,0.32)',
                          }}>{item.status.name}</Badge></td>
                          <td>
                              <div className="d-flex align-items-center justify-content-center">
                                  <Button onClick={() => {
                                      props.history.push(`/acciones-comerciales?project=${item.id}`)
                                  }} className="ml-2" variant="outline-primary" size="sm">Ver</Button>
                              </div>
                          </td>
                          <td>
                              <div className="d-flex align-items-center justify-content-center">
                                  <Button onClick={() => {
                                    window.location.href = '/nueva-accion?project=' + item.id;
                                  }} className="ml-2" variant="success" size="sm">
                                      <i className="fas fa-plus"></i>
                                  </Button>
                                  <Button onClick={() => {
                                    window.location.href = '/proyecto/' + item.id;
                                  }} className="ml-2" variant="warning" size="sm">
                                      <i className="fas fa-edit"></i>
                                  </Button>
                                  <ProjectStopButton project={item}></ProjectStopButton>
                              </div>
                          </td>
                      </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>
            <div className="d-flex justify-content-between align-items-center">
                <PaginationTables></PaginationTables>
                <PaginationPaginationSize></PaginationPaginationSize>
            </div>
        </>
    );
}

export default withRouter(React.memo(ProjectsTable));