import dayjs from 'dayjs';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { InfoBadge } from '../../ui/Badge';
import DangerBadge from '../../ui/Badge/DangerBadge';
import { InfoProgressBar, WarnProgressBar } from '../../ui/ProgressBar';
import { FollowCommercialData } from '../entities/followCommercialRequest';
import { selectCommercialData } from '../_redux/followCommercial/FollowCommercialSelector';

export interface FollowActionInfoProps {

}

const DateLabel = (props: { children: string }) => (
  <label style={{
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    marginBottom: '0.5rem'
  }}>{props.children}</label>
);

const Value = (props: { children: any, color?: string }) => (
  <div style={{
    color: props.color || 'black',
    fontWeight: 'bold',
    fontSize: '1.5rem'
  }}>{props.children}</div>
);

const ValueContainer = (props: any) => (
  <div style={{
    padding: '10px 20px',
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    borderRight: !props.withoutBorder ? '0.5px solid black' : 'none',
  }}>{props.children}</div>
)

const FollowActionInfo: React.SFC<FollowActionInfoProps> = (props: FollowActionInfoProps) => {
  const commercialData: FollowCommercialData = useSelector(selectCommercialData);
  return (
    <>
      <Row>
        <Col xs="4" md="2">
          <img width="100%" alt="" src={commercialData?.base?.image} />
        </Col>
        <Col xs="8" md="10">
          <Row>
            <Col>
              <div>{commercialData?.base?.name}</div>
              <div>{commercialData?.base?.description}</div>
            </Col>
          </Row>
          <Row className="mt-5 align-items-center">
            <Col className="d-flex" md="4">
              {commercialData?.base?.start && <div>
                <DateLabel>FECHA INICIO</DateLabel>
                <InfoBadge>{dayjs(commercialData?.base?.start).format('d MMM YYYY')}</InfoBadge>
              </div>}
              {commercialData?.base?.end && <div className="ml-5">
                <DateLabel>FECHA FIN</DateLabel>
                <DangerBadge>{dayjs(commercialData?.base?.end).format('d MMM YYYY')}</DangerBadge>
              </div>}
            </Col>
            <Col>
              <DateLabel>PROGRESO</DateLabel>
              <WarnProgressBar
                showProgressLabel
                total={commercialData?.base?.pdvs}
                value={commercialData?.base?.impacted_pdvs}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex">
            <ValueContainer>
              <DateLabel>COSTO DE LA EJECUCIÓN COP</DateLabel>
              <Value>
                ${commercialData?.base?.total_pesos}
              </Value>
            </ValueContainer>
            <ValueContainer>
              <DateLabel>VALOR PDV COP</DateLabel>
              <Value>
                ${commercialData?.base?.total_pesos_x_pdv}
              </Value>
            </ValueContainer>
            <ValueContainer>
              <DateLabel>Total PDVs</DateLabel>
              <Value color="#0B7891">
                {commercialData?.base?.pdvs}
              </Value>
            </ValueContainer>
            <ValueContainer>
              <DateLabel>PDVs Efectivos</DateLabel>
              <Value color="#0B7891">
                {commercialData?.base?.impacted_pdvs}
              </Value>
            </ValueContainer>
            <ValueContainer>
              <div style={{
                width: '400px'
              }}>
                <InfoProgressBar
                  withExplanation
                  progressLabel="Efectivos"
                  lessLabel="PDVs"
                  value={commercialData?.base?.impacted_pdvs}
                  total={commercialData?.base?.pdvs}
                />
              </div>
            </ValueContainer>
            <ValueContainer>
              <DateLabel>Super Vecino</DateLabel>
              <Value color="#0B7891">
                {commercialData?.base?.super_vecino_cant}
              </Value>
            </ValueContainer>
            <ValueContainer withoutBorder>
              <DateLabel>No inscritos</DateLabel>
              <Value color="#0B7891">
                {commercialData?.base?.not_registered_cant}
              </Value>
            </ValueContainer>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default FollowActionInfo;
