import * as React from 'react';
import { object, string, number, array, date } from 'yup';
import BasicFilters from './BasicFilters';
import ActionStep, {ActionTypes} from './ActionStep';
import { validationMessages } from '../../../validations';
import AdvancedFilters from './AdvancedFilters';
import StrategicFilters from './StrategicFilters';
import _ from 'lodash'
import useQuery from '../../Navigation/hooks/useQuery';
import {useFormikWizard} from 'formik-wizard';

const useCActivityWizardSteps = (): any => {
  const query = useQuery()
  const project = query.get('project')
  const initialValues = React.useMemo(() => ({project_id: project}), [project])
  const d = useFormikWizard()
  const steps = React.useMemo(() => [

    {
      id: 'basic',
      component: (BasicFilters as any),
      keepValuesOnPrevious: true,
      initialValues: {
        ...initialValues,
        name: '',
        localities_array: [],
        description: '',
        country: '170',
        action_type_id: 16,
        segment_type_id: 18,
        campaign: {
          questions: [],
        },
        cooler_without: {
          inferior: "",
          superior: ""

        },
      },
      validationSchema: object().shape({
        name: string().required(validationMessages.FIELD_REQUIRED),
        cities: string().required(validationMessages.FIELD_REQUIRED),
        description: string().required(validationMessages.FIELD_REQUIRED),
        comercial_action_type_id: string().required('Por favor selecciona el tipo de accción comercial')
      }),
    },
    {
      id: 'advanced',
      keepValuesOnPrevious: true,
      component: AdvancedFilters as any,
    },
    {
      id: 'strategic',
      component: StrategicFilters as any,
      keepValuesOnPrevious: true,

      validationSchema: object().shape({
        cooler_with: object().shape({
          inferior: number()
            .when('superior', (val: any, schema: any) => {
              return val ? schema.required('Campo requerido') : schema;
            })
            .when('superior', (val: Number, schema: any) => {
              return val ? schema.max(val, `Debe ser menor a ${val}`): schema;
            }),
        superior: number()
          .when('inferior', (val: any, schema: any) => {
            return val ? schema.required('Campo requerido') : schema;
          })
          .when('inferior', (val: any, schema: any) => {
            return val ? schema.min(val, `Debe ser mayor a ${val}`): schema;
          })
          ,
        }, [['superior', 'inferior']]),
        cooler_without: object().shape({
          inferior: number()
            .when('superior', (val: any, schema: any) => {
              return val ? schema.required('Campo requerido') : schema;
            })
            .when('superior', (val: Number, schema: any) => {
              return val ? schema.max(val, `Debe ser menor a ${val}`): schema;
            }),
        superior: number()
          .when('inferior', (val: any, schema: any) => {
            return val ? schema.required('Campo requerido') : schema;
          })
          .when('inferior', (val: any, schema: any) => {
            return val ? schema.min(val, `Debe ser mayor a ${val}`): schema;
          })
        }, [['superior', 'inferior']]),
        declared_sales: array().of(object().shape({
          inferior: number()
            .when('superior', (val: any, schema: any) => {
              return val ? schema.required('Campo requerido') : schema;
            })
            .when('superior', (val: Number, schema: any) => {
              return val ? schema.max(val, `Debe ser menor a ${val}`): schema;
            }),
        superior: number()
          .when('inferior', (val: any, schema: any) => {
            return val ? schema.required('Campo requerido') : schema;
          })
          .when('inferior', (val: any, schema: any) => {
            return val ? schema.min(val, `Debe ser mayor a ${val}`): schema;
          })
        }, [['superior', 'inferior']])),
        categories_baskets: array().of(object().shape({
        inferior: number()
            .when('superior', (val: any, schema: any) => {
              return val ? schema.required('Campo requerido') : schema;
            })
            .when('superior', (val: Number, schema: any) => {
              return val ? schema.max(val, `Debe ser menor a ${val}`): schema;
            }),
        superior: number()
          .when('inferior', (val: any, schema: any) => {
            return val ? schema.required('Campo requerido') : schema;
          })
          .when('inferior', (val: any, schema: any) => {
            return val ? schema.min(val, `Debe ser mayor a ${val}`): schema;
          }) 
        }, [['superior', 'inferior']])),
        categories: object().shape({
         inferior: number()
            .when('superior', (val: any, schema: any) => {
              return val ? schema.required('Campo requerido') : schema;
            })
            .when('superior', (val: Number, schema: any) => {
              return val ? schema.max(val, `Debe ser menor a ${val}`): schema;
            }),
        superior: number()
          .when('inferior', (val: any, schema: any) => {
            return val ? schema.required('Campo requerido') : schema;
          })
          .when('inferior', (val: any, schema: any) => {
            return val ? schema.min(val, `Debe ser mayor a ${val}`): schema;
          }) 
        }, [['superior', 'inferior']]),
      })

    },
    {
      id: 'action',
      component: (ActionStep as any),
      keepValuesOnPrevious: true,
      actionLabel: 'Proceder',
      onAction: (sectionValues: any, formValues: any, ...d: any) => {
        console.log(sectionValues, formValues, d)
      },
      
    },
  ], [initialValues])

  return steps;
}


export default useCActivityWizardSteps;
