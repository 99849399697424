import * as React from 'react';
import { Col, Form } from 'react-bootstrap';

export interface PaginationPaginationSizeProps {
  from?: number,
  to?: number,
}
 
const PaginationPaginationSize: React.SFC<PaginationPaginationSizeProps> = ({from = 1, to = 1}: PaginationPaginationSizeProps) => {
  return (
    <div className="row align-items-center">
        <Col>
            <Form.Control as="select">
                <option value="10">10</option>
            </Form.Control>
        </Col>
        <Col style={{ minWidth: 208}}>
            Mostrando filas 1 a {from} de {to}
        </Col>
    </div>
  );
}
 
export default PaginationPaginationSize;