import { Formik } from 'formik';
import * as React from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import ProjectSelect from '../modules/Projects/components/ProjectSelect';
import PageContainer from './PageContainer';
import {object, string} from 'yup'
import { validationMessages } from '../validations';
import AjaxFilter from '../modules/commercialActivities/components/AjaxFilter';
import FormikBootstrapInput from '../modules/ui/FormikBootstrapInput';
import useFetch from 'use-http';
import Picker from 'emoji-picker-react';

import NotificationsTypeRadio from '../modules/notifications/NotificationsTypeRadio';
import Swal from 'sweetalert2';

export interface NotificationPageProps {
  
}

const validationSchema = object().shape({
  project_id: string().required(validationMessages.FIELD_REQUIRED),
  action_type_id: string().required(validationMessages.FIELD_REQUIRED),
  comercial_action_type_id: string().required(validationMessages.FIELD_REQUIRED),
  title: string().required(validationMessages.FIELD_REQUIRED),
  text: string().required(validationMessages.FIELD_REQUIRED),
})
 
const NotificationPage: React.SFC<NotificationPageProps> = (props: NotificationPageProps) => {
  const {post, response} = useFetch(`${process.env.REACT_APP_API_HOST}/api/notifications`, {
    cacheLife: 0
  });
  const initialValues = {
    project_id: '',
    action_type_id: '',
    comercial_action_type_id: '',
    item_id: '',
    title: '',
    text: '',
  };
  const [showEmojis, setShowEmojis] = React.useState(false)

  const handleSubmitForm = React.useCallback(async (values) => {
    await post({
      ...values,
      module_type_id: ''
    });
    if (response.ok) {
      Swal.fire({
        icon: 'success',
        text: 'Notificación enviada'
      })
    } else {
      Swal.fire({
        icon: 'error',
        text: 'Notificación enviada'
      })
    }
  }, [post, response.ok]);

  return (
    <PageContainer>
      <Card.Header>
        <Card.Title>Notificaciones Push</Card.Title>
      </Card.Header>
      <Card.Body>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmitForm}
        >
          {({ values, setFieldValue, handleSubmit, errors, isSubmitting }) => (
            <>
              <Row>
                <Col xs="6" md="4">
                  <Form.Group>
                    <Form.Label>Proyecto</Form.Label>
                    <ProjectSelect name="project_id"></ProjectSelect>
                    <Form.Text className="text-muted">
                        Ingrese el dato
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs="6" md="4">
                  <Form.Group>
                    <Form.Label>Tipo de Acción</Form.Label>
                    <AjaxFilter labelField="name" name="action_type_id" url={`${process.env.REACT_APP_API_HOST}/api/types/action/type/list`}></AjaxFilter>
                    <Form.Text className="text-muted">
                        Ingrese el dato
                    </Form.Text>
                  </Form.Group>
                </Col>
                {values.action_type_id && <Col xs="6" md="4">
                  <Form.Group>
                    <Form.Label>Tipo de Ejecución</Form.Label>
                    <AjaxFilter
                      watch={[values.action_type_id]}
                      onChange={() => {
                        setFieldValue('item_id', '')
                      }}
                      labelField="name" name="comercial_action_type_id"
                      url={values.action_type_id === '15' ? `${process.env.REACT_APP_API_HOST}/api/types/promotion/type/list` : `${process.env.REACT_APP_API_HOST}/api/types/comercial/type/list`}
                    ></AjaxFilter>
                    <Form.Text className="text-muted">
                        Ingrese el dato
                    </Form.Text>
                  </Form.Group>
                </Col>}
                {values.comercial_action_type_id && values.project_id && <Col xs="6" md="4">
                  <Form.Group>
                    <Form.Label>Acción</Form.Label>
                    <AjaxFilter labelField="name" name="item_id"
                      url={`${process.env.REACT_APP_API_HOST}/api/search/comercial/action?project=${values.project_id}&comercial_action_type=${values.comercial_action_type_id}`}
                      watch={[
                        values.comercial_action_type_id,
                        values.project_id,
                      ]}
                    ></AjaxFilter>
                    <Form.Text className="text-muted">
                        Ingrese el dato
                    </Form.Text>
                  </Form.Group>
                </Col>}
              </Row>
              <Row>
                <Col xs="12" md="8">
                  <Form.Group>
                    <Form.Label>Título</Form.Label>
                    <FormikBootstrapInput name="title" />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Texto a enviar en el push</Form.Label>
                    <FormikBootstrapInput name="text" as="textarea"/>
                    <div style={{ position: 'absolute', top: '50%', right: 20, zIndex: 999}}>
                      {!showEmojis && <button className="btn" onClick={() => setShowEmojis(!showEmojis)}><i className="far fa-smile-beam"></i></button>}
                      {showEmojis && <Picker onEmojiClick={(_, {emoji}) => {
                        setFieldValue('text', `${values.text}${emoji}`)
                        setShowEmojis(false)
                      }} />}
                    </div>
                  </Form.Group>
                </Col>
                <Col xs="12" md="4">
                  <Form.Group>
                    <Form.Label>A quien va dirigido</Form.Label>
                    <Row>
                      <Col>
                      <NotificationsTypeRadio></NotificationsTypeRadio>
                      </Col>
                    </Row>
                    {/* <FormikBootstrapInput name="recipients_type_id" type="" value="1" /> */}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                    <Button disabled={isSubmitting} onClick={() => handleSubmit()} type="submit">{isSubmitting ? 'Enviando' : 'Enviar Notificación'}</Button>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </Card.Body>
    </PageContainer>
  );
}
 
export default NotificationPage;