import * as React from 'react';
import CounterCard from './CounterCard';

export interface PollsCounterProps {
  total: any;
}
 
const PollsCounter: React.SFC<PollsCounterProps> = (props: PollsCounterProps) => {
  return (
    <CounterCard color="#F15E3D" count={props.total} label="Encuestas" logo="/img/poll.svg"></CounterCard>
  );
}
 
export default PollsCounter;