import * as React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import PageContainer from '../../../pages/PageContainer';
import OrderTable from '../components/OrderTable';
import PromoSelect from '../components/PromoSelect';
import { orderIdNulleable } from '../hooks/useOrderList';


export interface PromocionesPageProps {
  
}
 
const PromocionesPage: React.SFC<PromocionesPageProps> = (props: PromocionesPageProps) => {
  const [orderId, setOrderId] = React.useState<orderIdNulleable>(null);

  return (
    <PageContainer>
      <Card.Body>
        <Row>
          <Col>
            <Form.Label>Seleccione una promoción</Form.Label>
            <PromoSelect
              selectedId={orderId}
              setSelectedId={setOrderId}
              placeholder="Seleccione"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <OrderTable orderId={orderId} />
          </Col>
        </Row>
      </Card.Body>
    </PageContainer>
  );
}
 
export default PromocionesPage;