import cond from 'lodash/cond';
import constant from 'lodash/constant';
import stubTrue from 'lodash/stubTrue';
import * as React from 'react';
import useFetch from 'use-http';
import { getGenericHeaders } from '../../../../redux';
import { Select } from '../../../../_metronic/_partials/controls';

export interface ProjectStatusSelectProps {
  
}
 
const ProjectStatusSelect: React.SFC<ProjectStatusSelectProps> = (props: ProjectStatusSelectProps) => {
  const { loading, data: res = {}, error} = useFetch(`${process.env.REACT_APP_API_HOST}/api/types/project/status`, {
    headers: getGenericHeaders()
  }, [])
  const { data = [] } = res;
  
  const placeholder = cond([
    [constant(loading), constant('Cargando...')],
    [constant(!!error), constant('Error conectando con el servidor')],
    [stubTrue, constant('Todos los estados')]
  ])(0)

  const options = data.map((d: any) => ({
    label: d.name,
    value: d.id
  }))

  return (
    <Select
      name="status_type_id"
      placeholder={placeholder}
      options={options}
    />
  );
}
 
export default ProjectStatusSelect;