import { getGenericHeaders } from '../../../../redux';

export const GET_DB_TYPES_URL = `${process.env.REACT_APP_API_HOST}/api/types/database`;
export const GET_CURRENT_DB = `${process.env.REACT_APP_API_HOST}/api/setup/database`;
export const UPDATE_DB_URL = `${process.env.REACT_APP_API_HOST}/api/setup/database`;

export interface DbType {
    "id": number,
    "name": string,
    "typification_id": number,
    "extra_data": string,
    "country_id": null | string,
    "created_at": null | string,
    "updated_at": null | string,
    "deleted_at": null | string
}


export async function updateDb(database: string): Promise<void> {
    await fetch(UPDATE_DB_URL, {
        method: 'post',
        headers: getGenericHeaders(),
        body: JSON.stringify({
            database,
        })
    })
}