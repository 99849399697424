import * as React from 'react';
import { Button, ButtonProps, OverlayTrigger, Tooltip } from 'react-bootstrap';

export interface ButtonTooltipProps extends ButtonProps {
  children: React.ReactNode,
  tooltipText: string,
  className: string,
  onClick?: any,
}
 
const ButtonTooltip: React.SFC<ButtonTooltipProps> = ({children, tooltipText, ...props}: ButtonTooltipProps) => {
  const renderTooltip = React.useCallback((props) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltipText}
    </Tooltip>
  ), [tooltipText])

  return (
    <OverlayTrigger
    placement="top"
    delay={{ show: 250, hide: 400 }}
    overlay={renderTooltip}
  >
    <Button {...props}>
      {children}
    </Button>
  </OverlayTrigger>
  );
}
 
export default ButtonTooltip;