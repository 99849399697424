import * as React from 'react';
import { Divider } from '@material-ui/core';
import { Button, Col, Row } from 'react-bootstrap';
import QuestionListItem from '../modules/question/components/QuestionListItem';
import DragAndDropList from '../../ui/DragAndDrop/components/DragAndDropList';
import { useFormikContext } from 'formik';
import _ from 'lodash'
import Swal from 'sweetalert2';

type Props = {
  questions: any[],
  helpers: any
};

export function QuestionsList({ questions, helpers }: Props) {
  const {setFieldValue} = useFormikContext<any>()
  const _handleRemoveItem = React.useCallback((index: number) => {
    helpers.remove(index)
  }, [helpers])


  return <>
    <Row className="justify-content-between my-4 align-items-center">
      <Col><div className="text-primary">Preguntas</div></Col>
    </Row>
    <Divider style={{ backgroundColor: '#0E8BA9' }}></Divider>

    <DragAndDropList<any>
      data={questions}
      onDropEnd={async (dragIndex, dropIndex) => {
        const result = await Swal.fire({
          html: 'Al cambiar de orden las preguntas <strong>se borraran todas las redirecciones creadas entre preguntas</strong>, ¿está seguro que desea continuar?',
          allowEnterKey: true,
          allowEscapeKey: true,
          showCancelButton: true,
          icon: "warning",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Cambiar orden"
        })
        if (result.isConfirmed) {
          const questionsDraft = _.cloneDeep(questions);
          questionsDraft.splice(dropIndex, 0, questionsDraft.splice(dragIndex, 1)[0]);
          questionsDraft.forEach((question: any, index) => {
            question.number = index + 1;
            question.options.forEach((option: any) => {
              option.go_to = ''
            })
          })
          setFieldValue('campaign.questions', questionsDraft)
        }
      }}
      renderItem={(item, index) => (
        <QuestionListItem
          key={String(index)}
          onRemoveItem={_handleRemoveItem}
          index={index}
        ></QuestionListItem>
      )}
    />
    <Row className="mt-2">
      <Col className="text-right">
        <Button variant="secondary" onClick={() => helpers.push({ options: [], name: '', photo: ``, number: questions.length })}><i className="fas fa-plus" /> Agregar Pregunta</Button>
      </Col>
    </Row>
  </>;
}
