import * as React from 'react';
import CounterCard from './CounterCard';

export interface NewsCounterProps {
  total: any
}
 
const NewsCounter: React.SFC<NewsCounterProps> = (props: NewsCounterProps) => {
  return (
    <CounterCard color="#F10544" count={props.total} label="Noticias" logo="/img/messsage.svg"></CounterCard>
  );
}
 
export default NewsCounter;