import * as React from 'react';
import useFetch from 'use-http';
import { Select } from '../../../../_metronic/_partials/controls';

export interface ProjectSelectProps {
  name: string;
}

const ProjectSelect: React.SFC<ProjectSelectProps> = (props: ProjectSelectProps) => {
  const {data = {}, loading} = useFetch(`${process.env.REACT_APP_API_HOST}/api/project/select/get`, {}, []);
  const {data: projects = []} = data
  const options = projects.map((p: any) => ({
    value: p.id,
    label: p.name
  }))

  return (
    <Select
      {...props}
      isLoading={loading}
      placeholder="Selecciona uno"
      options={options}
    />
  );
}

export default ProjectSelect;