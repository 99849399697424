import * as React from 'react';
import GoogleMapReact from 'google-map-react';
import usePosition from '../../location/hooks/usePosition';
import Loading from '../../ui/Loading';
import { Coordinate } from '../../location/entities/Coordinate';
export interface PdvMapProps {
    pdvs: any,
    defaultCenter: Coordinate,
    setBounds: any
}
export interface MapBounds {
  ne: Coordinate,
  sw: Coordinate,
}

const AnyReactComponent = ({ onPressMarker, executed, ...rest }: any) =>
  <>
    <i className="fas fa-store" style={{color: executed ? '#EB3815' : '#3497fd', fontSize: '2em'}}></i>
  </>;

const PdvMap: React.SFC<PdvMapProps> = ({pdvs, defaultCenter, setBounds}: PdvMapProps) => {
    const mapRef = React.useRef<GoogleMapReact>(null);

    return (
        <div style={{ width: '100%', paddingTop: '60%', position: 'relative'}}>
            <div style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
            }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyBRdm5w4YtvLwAMuxukGZp6AZvqpxDifgE" }}
                    defaultCenter={defaultCenter}
                    ref={mapRef}
                    onChange={({bounds}) => {
                      setBounds(bounds)
                    }}
                    defaultZoom={16}
                >
                    {pdvs.map((pdv: any) => !pdv.pdv.lat ? null : (
                      <AnyReactComponent
                        onPressMarker={() => {
                        }}
                        executed={pdv.response}
                        key={String(pdv.id)}
                        lat={pdv.pdv.lat}
                        lng={pdv.pdv.lon}
                        text=""
                      />
                    ))}
                </GoogleMapReact>
            </div>
        </div>
    );
}

export default React.memo(PdvMap);