import * as React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import {ContentState, convertFromHTML, convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import { FieldProps } from 'formik';
import { Form } from 'react-bootstrap';
import _ from 'lodash';


export interface EditorFieldProps extends FieldProps<any> {}

const EditorField: React.SFC<EditorFieldProps> = (props: EditorFieldProps) => {
  const errors = _.get(props.form.errors, props.field.name)
  
  // @ts-ignore
  const contentDataState =  ContentState.createFromBlockArray(convertFromHTML(props.field.value || ''));
  
  
  const [editorState, setEditorState] = React.useState(EditorState.createWithContent(contentDataState))
  
  React.useEffect(() => {
    props.form.setFieldValue(props.field.name, stateToHTML(editorState.getCurrentContent()))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState, props.field.name])
  
  return (
    <div>
      <Editor
        defaultEditorState={EditorState.createWithContent(contentDataState)}
        initialContentState={contentDataState}
        toolbarClassName="toolbarClassName"
        wrapperClassName={`wrapper_EditorField ${errors && 'is-invalid'}`}
        EditorFieldClassName="EditorFieldClassName"
        onEditorStateChange={setEditorState}
      />
      <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
    </div>
  )
}
 
export default React.memo(EditorField);