import * as React from 'react';

export interface ProgressBarBaseProps {
  total: number,
  value: number,
  color: string;
  showProgressLabel?: boolean;
  progressLabel?: string;
  lessLabel?: string;
  withExplanation?: boolean
}
 
const ProgressBarBase: React.SFC<ProgressBarBaseProps> = (props: ProgressBarBaseProps) => {
  const progress = (props.value * 100) / props.total;


  return (
    <div>
      {props.withExplanation && (
        <div style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          fontWeight: 'bold',
          justifyContent: 'space-between'
        }}>
          <div style={{
            color: props.color
          }}>{progress.toFixed(2)}% {props.progressLabel}</div>
          <div>100% {props.lessLabel}</div>
        </div>
      )}
      <div style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center'
      }}>
        <div
          style={{
            flex: 1,
            height: '0.75rem',
            backgroundColor: '#E0E0E0',
            borderRadius: '0.3125rem',
            overflow: 'hidden'
          }}
        >
          <div style={{
            width: `${progress}%`,
            background: props.color,
            height: '100%',
          }} />
        </div>
        {props.showProgressLabel && <div className="ml-4" style={{
          fontWeight: 'bold'
        }}>{progress.toFixed(2)}%</div>}
      </div>
    </div>
  );
}
 
export default ProgressBarBase;