/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Provider as HttpProvider } from 'use-http'
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'dayjs/locale/es'
import dayjs from "dayjs";
import * as yup from 'yup';
import yup_es from "./utils/yup_es";
import toastr from "toastr";

toastr.options.toastClass = 'toastr';

dayjs.locale("es");

// @see https://github.com/jquense/yup/issues/293#issuecomment-478200159
yup.setLocale(yup_es);

const options = {
  interceptors: {
    // every time we make an http request, this will run 1st before the request is made
    // url, path and route are supplied to the interceptor
    // request options can be modified and must be returned
    request: async ({ options, url, path, route }) => {
      const token = localStorage.getItem('token')
      if (token) {
        options.headers.Authorization = `Bearer ${token}`
      }
      return options
    },
    // every time we make an http request, before getting the response back, this will run
    response: async ({ response }) => {
      const res = response
      if (res.status == 401) {
        localStorage.clear()
        window.location.href = '/';
      }
      return res
    }
  }
}

export default function App({ store, persistor, basename }) {
  return (
    /* Provide Redux store */
    <Provider store={store}>
      <HttpProvider url={process.env.REACT_APP_API_HOST} options={options}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <Routes />
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
      </HttpProvider>
    </Provider>
  );
}
