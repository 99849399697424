import React, {Suspense} from "react";
import {Redirect, Switch} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {BuilderPage} from "./pages/BuilderPage";
import {MyPage} from "./pages/MyPage";
import {DashboardPage} from "./pages/DashboardPage";
import { SetupPage } from './pages/SetupPage'
import ProjectsPage from "./pages/ProjectsPage";
import NewProjectPage from "./modules/Project/pages/NewProjectPage";
import CActivitiesPage from "./modules/commercialActivities/pages/CActivitiesPage";
import AttackOportunityPage from "./modules/Oportunities/pages/AttackOportunityPage";
import CActivitiesNewPage from "./modules/commercialActivities/pages/CActivitiesNewPage";
import FollowPage from "./modules/Dashboard/pages/FollowPage";
import NotificationPage from "./pages/NotificationPage";
import PdvMapPage from "./modules/Pdv/pages/PdvMapPage";
import PromocionesPage from "./modules/Promos/pages/PromocionesPage";
import FollowActionDetailPage from "./modules/Follow/pages/FollowActionDetailPage";
import UpdateProjectPage from "./pages/UpdateProjectPage";
import CActivitiesEditPage from "./modules/commercialActivities/pages/CActivitiesEditPage";



export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <ContentRoute path="/promociones" component={PromocionesPage}/>
                <ContentRoute path="/dashboard" component={DashboardPage}/>
                <ContentRoute path="/action-map" component={PdvMapPage}/>
                <ContentRoute path="/builder" component={BuilderPage}/>
                <ContentRoute path="/my-page" component={MyPage}/>
                <ContentRoute path="/setup" component={SetupPage}/>
                <ContentRoute path="/proyectos" component={ProjectsPage}/>
                <ContentRoute path="/nuevo-proyecto" component={NewProjectPage}/>
                <ContentRoute path="/proyecto/:id" component={UpdateProjectPage} />
                <ContentRoute path="/acciones-comerciales" component={CActivitiesPage}/>
                <ContentRoute path="/atacar-oportunidad" component={AttackOportunityPage}/>
                <ContentRoute path="/nueva-accion" component={CActivitiesNewPage}/>
                <ContentRoute path="/seguimiento" component={FollowPage}/>
                <ContentRoute path="/notificaciones" component={NotificationPage} />
                <ContentRoute path="/seguimiento-actividad/:id" component={FollowActionDetailPage} />
                <ContentRoute path="/accion-comercial/:id" component={CActivitiesEditPage} />
                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
