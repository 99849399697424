import React from "react";
import {useField} from "formik";
import RSelect from 'react-select';
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";
import _ from 'lodash'


export function Select({
  label,
  withFeedbackLabel = true,
  type = "text",
  customFeedbackLabel,
  name,
  options,
  ...props
}: {
  label?: string,
  name: string,
  placeholder?: string,
  withFeedbackLabel?: boolean,
  type?: string,
  options: any,
  isDisabled?: boolean,
  isLoading?: boolean,
  group?: any,
  onChange?: any,
  isMulti?: boolean,
  customFeedbackLabel?: string,
}) {
  const [field, meta, helper] = useField(name);
  const [,, helperLabel] = useField(`${name}_label`);
  const [,, groupValue] = useField(`${name}_g`);
  const { touched, error } = meta;


  const getValue = () => {
    if (props.isMulti) {
      return options ? 
      options.filter((option: any) => field.value && field.value.indexOf(option.value) > -1) :
      [];
    }
    return options ? options.find((option: any) => option.value === field.value) : '';
  }

  const handleChange = (options: any) => {
    
    if (props.isMulti) {

      if (!_.isArray(options)) {
        options = []
      }
          props.onChange && props.onChange(options);

      const nextValue = options.map((o: any) => o.value); 
      groupValue.setValue(props.group)
      helper.setValue(nextValue)

      helperLabel.setValue(options.map((o: any) => o.label).join(', '))
      return;
    }
        console.log('pt',options)

        props.onChange && props.onChange(options);

    helper.setValue(options.value)
    helperLabel.setValue(options.label)
  }


  return (
    <div>
      {label && <label>{label}</label>}
      <RSelect
        className={`${error && 'is-invalid is-invalid-select'}`}
        {...field}
        {...props}
        options={options}
        value={getValue()}
        onChange={handleChange}
      >
      </RSelect>
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          type=""
          error={_.isString(error) && error !== '' ? error : undefined}
          touched={touched}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </div>
  );
}
