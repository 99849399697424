import * as React from "react";
import { Col, Row, Card } from "react-bootstrap";
import toastr from "toastr";
import { useHistory, useParams } from "react-router";
import { updateProject } from "../modules/Project/store/projectCrud";
import ProjectForm, { ProjectFormValues } from "../modules/Project/components/ProjectForm";
import useFetch from "use-http";
import Loading from "../modules/ui/Loading";
import _ from 'lodash';

export interface NewProjectPageProps {}

const NewProjectPage: React.SFC<NewProjectPageProps> = (
  props: NewProjectPageProps
) => {
  const history = useHistory();
  const {id} = useParams<{id: string}>();
  const {loading, error, data = {}} = useFetch(`/api/project/${id}`, [id]);
  const project: ProjectFormValues = _.get(data, 'data', {});

  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Card.Title>{project.name}</Card.Title>
          </Card.Header>
          {error && (
            <div className="alert alert-danger">Error conectando con el servidor</div>
          )}
          {loading ? <Loading /> : (
            <ProjectForm
              buttonLabel="Actualizar"
              initialValues={project}
              onSubmit={async (values: ProjectFormValues) => {
              try {
                  await updateProject(values);
                  toastr.success('Proyecto actualizado correctamente')
                  history.push("/proyectos");
                } catch (ex) {
                  toastr.error(ex.message)
                }
              }}
            ></ProjectForm>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default NewProjectPage;
