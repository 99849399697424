import * as React from 'react';
import NewCActivityWizard from '../components/NewCActivityWizard';
import { Card, Col } from 'react-bootstrap';


export interface CActivitiesNewPageProps {
  
}
 
const CActivitiesNewPage: React.SFC<CActivitiesNewPageProps> = (props: CActivitiesNewPageProps) => {
  return (
      <div className="row">
          <Col>
            <Card>
                <Card.Body>
                    <NewCActivityWizard></NewCActivityWizard>
                </Card.Body>
            </Card>
          </Col>
      </div>
  );
}
 
export default CActivitiesNewPage;