import { Map } from 'immutable';
import { FollowCommercialState } from '../../entities/FollowCommercialState';
import { ActionTypes, FollowCommercialActionTypes } from './FollowCommercialActions';

const initialState = Map({
  data: {},
}) as FollowCommercialState;

export function followCommercialReducer(
  state = initialState,
  action: FollowCommercialActionTypes,
): FollowCommercialState {
  if (action.type === ActionTypes.SET_ERROR) {
    if ('msg' in action) {
      return state.set('errorMsg', action.msg).set('isLoading', false);
    }
    throw new Error(`${ActionTypes.SET_ERROR} - Debe especificar un mensaje`);
  }
  if (action.type === ActionTypes.SET_LOADING) {
    if ('payload' in action) {
      return state.set('isLoading', action.payload)
    }
    throw new Error(`${ActionTypes.SET_LOADING} - Debe especificar un estado`)
  }
  if (action.type === ActionTypes.SET_DATA) {
    if ('payload' in action) {
      return state.set('data', action.payload).set('isLoading', false)
    }
    throw new Error(`${ActionTypes.SET_LOADING} - Debe especificar un payload`)
  }
  return state;
}