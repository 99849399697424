import swal from 'sweetalert2'
export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        /* config.withCredentials = true */
        /* config.xsrfCookieName = undefined
        config.xsrfHeaderName = undefined */
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      const {xsrfCookieName, xsrfHeaderName, ...configUpdated} = config
      return configUpdated;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    if (401 === error.response.status) {
        swal.fire({
            text: "Tu sesión expiró",
            icon: "warning",
            showCancelButton: false,
            confirmButtonText: "Ok",
            closeOnConfirm: false
        }, function(){
            window.location = '/login';
        });
    } else {
        return Promise.reject(error);
    }
});
}
