import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import ColorCard from '../../ui/ColorCard';

export interface ActionsComCountProps {
  total: any
}

const ActionsComCount: React.SFC<ActionsComCountProps> = (props: ActionsComCountProps) => {
  return (
    <ColorCard color="#03738B">
      <Row style={{
        alignItems: 'center',
        padding: '50px 0',
        justifyContent: 'center'
      }}>
        <Col className="text-white">
          <div style={{
            marginBottom: 20
          }}>ACCIONES TOTALES</div>
          {/* <div><Button style={{
            backgroundColor: '#049BBD'
          }}>Exportar {'>'}</Button></div> */}
        </Col>
        <Col className="text-white">
          <div style={{
            fontWeight: 'bold',
            fontSize: 50
          }}>{props.total}</div>
          <div>Acciones</div>
        </Col>
      </Row>
    </ColorCard>
  );
}

export default ActionsComCount;