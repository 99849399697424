import * as React from 'react';
import BaseBadge from './BaseBadge';

export interface DangerBadgeProps {
  children: React.ReactNode
}
 
const DangerBadge: React.SFC<DangerBadgeProps> = (props: DangerBadgeProps) => {
  return (
    <BaseBadge {...props} background="#F7AC9B" color="#BB2D0D" />
  );
}
 
export default DangerBadge;