import * as React from 'react';
import useFetch from "use-http";
import { OrderListResponse } from '../entities/OrderListResponse';

export type orderIdNulleable = number | string | null;

export function useOrderList(orderId: orderIdNulleable) {
  const { get, response, error, loading } = useFetch<OrderListResponse>('/api/promotions/orders');

  const getOrderById = React.useCallback(async (id: string | number) => {
      const res = await get(`/${id}/list`)
      return res;
  }, [get])

  React.useEffect(() => {
    if (orderId) {
      getOrderById(orderId);
    }
  }, [getOrderById, orderId]);

  const orderResponse = React.useMemo(() => ({
    response,
    error,
    loading
  }), [error, loading, response])

  return orderResponse;
}
