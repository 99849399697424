import { Field } from 'formik';
import * as React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import FormikBootstrapInput from '../../ui/FormikBootstrapInput';
import EditorField from './EditorField';
import UploadFileField from './UploadFileField';

export interface NewsFormProps {

}

const NewsForm: React.SFC<NewsFormProps> = (props: NewsFormProps) => {
  return (
    <div>
      <Form.Group as={Row}>
        <Col>
          <Form.Label>Nombre:</Form.Label>
          <Field as={Form.Control} name="news_message.name"></Field>
        </Col>
        <Col>
          <Form.Label>image:</Form.Label>
          <UploadFileField url={`${process.env.REACT_APP_API_HOST}/api/comercial/action/image/save`} name="news_message.image"></UploadFileField>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col>
          <Form.Label>Descripción:</Form.Label>
          <Field component={EditorField} name="news_message.description"></Field>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col md="6">
          <Form.Label>Fecha Inicial</Form.Label>
          <FormikBootstrapInput
            type="datetime-local"
            name="news_message.start"
          ></FormikBootstrapInput>
        </Col>
        <Col md="6">
          <Form.Label>Fecha Final</Form.Label>
          <FormikBootstrapInput
            type="datetime-local"
            name="news_message.end"
          ></FormikBootstrapInput>
        </Col>
      </Form.Group>
    </div>
  );
}

export default NewsForm;