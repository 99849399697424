import { Field, FieldAttributes } from 'formik';
import * as React from 'react';
import { Form } from 'react-bootstrap';

export interface FormikBootstrapInputProps extends FieldAttributes<any> {
  as?: any;
  type?: string;
  placeholder?: string;
  children?: any;
  autotouch?: boolean;
}

const FormikBootstrapInput: React.FC<FormikBootstrapInputProps> = (props: FormikBootstrapInputProps) => {
  return (
    <Field {...props}>
      {({field, meta} : any) => (
        <>
          <Form.Control
            type={props.type}
            as={props.as}
            isInvalid={(meta.touched || props.autotouch) && meta.error}
            placeholder={props.placeholder}
            {...field}
          >{props.children}</Form.Control>
          <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
        </>
      )}
    </Field>
  );
}

export default FormikBootstrapInput;
