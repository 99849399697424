import { validationMessages } from './../../../validations';
import {object, string, lazy, mixed, date, ref, number} from 'yup';
import BasicFilterFields from './BasicFilterFields';
import CommercialActivitiesEditActionFields from './CommercialActivitiesEditActionFields';
import {produce} from 'immer';
import dayjs from 'dayjs';
import _ from 'lodash';

const dateAdapter = (items: any[]): any => {
  if (items.length == 0) {
    return null;
  }
  const [item] = items;
  return produce(item, (draft: any) => {
    draft.start = dayjs(draft.start).format('YYYY-MM-DDTHH:MM');
    draft.end = dayjs(draft.end).format('YYYY-MM-DDTHH:MM');
  })
}
export const editSteps = (initialValues: any) => [
  {
    id: 'basic',
    component: BasicFilterFields,
    initialValues: {
      name: initialValues.name,
      description: initialValues.description,
    },
    validationSchema: object().shape({
      name: string().required(validationMessages.FIELD_REQUIRED),
      description: string().required(validationMessages.FIELD_REQUIRED),
    }),
  },       
  {
    id: 'action',
    component: CommercialActivitiesEditActionFields,
    initialValues: {
      campaign: _.cond([
        [_.matches(true), () => {
          const campaign = dateAdapter(initialValues.campaigns)
          if (!campaign) {
            return null;
          }
          return produce(campaign, (draft: any) => {
            const questions = draft.questions.map((question: any, index: number) => {
              question.number = index + 1;
              question.options.forEach((option: any) => {
                const targetIndex = _.findIndex(campaign.questions, { id: option.go_to })
                if (targetIndex > -1) {
                  option.go_to = String(targetIndex);
                } else {
                  option.go_to = null;
                }
              })
              return question;
            })
            draft.questions = questions;
          })
        }],
        [_.matches(false), () => {
          const campaign = dateAdapter(initialValues.campaigns);
          if (!campaign) {
            return null;
          }
          return _.omit(campaign, ['questions']);
        }],
      ])(initialValues.answers_stored),
      promotion: dateAdapter(initialValues.promotion),
      news_message: dateAdapter(initialValues.promotional_message),
      answers_stored: initialValues.answers_stored,
      challenge: _.cond([
        [_.matches(true), _.constant(dateAdapter(initialValues.challenges))],
        [_.matches(false), () => {
          const challenge = dateAdapter(initialValues.challenges);
          if (!challenge) {
            return null;
          }
          return _.omit(challenge, ['questions']);
        }],
      ])(initialValues.answers_stored)
    },
    validationSchema: object().shape({
      campaign: lazy(value => {
        if (value == null) {
          return mixed();
        }
        return object().shape({
          name: string().required(validationMessages.FIELD_REQUIRED),
          photo: string().required(validationMessages.FIELD_REQUIRED),
          description: string().required(validationMessages.FIELD_REQUIRED).min(20, 'Debe tener al menos 20 caracteres'),
          start: date().required(validationMessages.FIELD_REQUIRED).min(dayjs().startOf('day').toDate(), 'Debe ser mayor a la fecha actual'),
          end: date().required(validationMessages.FIELD_REQUIRED).min(ref('start'), 'Debe ser mayor a la fecha de inicio'),
        })
      }),
      challenge: lazy(value => {
        if (value == null) {
          return mixed();
        }
        return object().shape({
          name: string().required(validationMessages.FIELD_REQUIRED),
          photos_quantity: number().required(validationMessages.FIELD_REQUIRED).min(1, 'Debe ser mayor o igual a 1'),
          promotional_image: string().required(validationMessages.FIELD_REQUIRED),
          reference_image: string().required(validationMessages.FIELD_REQUIRED),
          description: string().required(validationMessages.FIELD_REQUIRED).min(20, 'Debe tener al menos 20 caracteres'),
          instructions: string().required(validationMessages.FIELD_REQUIRED).min(20, 'Debe tener al menos 20 caracteres'),
          start: date().required(validationMessages.FIELD_REQUIRED).min(dayjs().startOf('day').toDate(), 'Debe ser mayor a la fecha actual'),
          end: date().required(validationMessages.FIELD_REQUIRED).min(ref('start'), 'Debe ser mayor a la fecha de inicio'),
        })
      }),
      promotion: lazy(value => {
        if (value == null) {
          return mixed();
        }
        return object().shape({
          name: string().required(validationMessages.FIELD_REQUIRED),
          image: string().required(validationMessages.FIELD_REQUIRED),
          detail: string().required(validationMessages.FIELD_REQUIRED).min(20, 'Debe tener al menos 20 caracteres'),
          start: date().required(validationMessages.FIELD_REQUIRED).min(dayjs().startOf('day').toDate(), 'Debe ser mayor a la fecha actual'),
          end: date().required(validationMessages.FIELD_REQUIRED).min(ref('start'), 'Debe ser mayor a la fecha de inicio'),
        })
      }),
      news_message: lazy(value => {
        if (value == null) {
          return mixed();
        }
        return object().shape({
          name: string().required(validationMessages.FIELD_REQUIRED),
          promotional_image: string().required(validationMessages.FIELD_REQUIRED),
          description: string().required(validationMessages.FIELD_REQUIRED).min(20, 'Debe tener al menos 20 caracteres'),
          start: date().required(validationMessages.FIELD_REQUIRED).min(dayjs().startOf('day').toDate(), 'Debe ser mayor a la fecha actual'),
          end: date().required(validationMessages.FIELD_REQUIRED).min(ref('start'), 'Debe ser mayor a la fecha de inicio'),
        })
      }),
    }),
  },       
]