import { Divider } from '@material-ui/core';
import { Formik } from 'formik';
import * as React from 'react';
import { Col, Row, Form, Card, Button } from 'react-bootstrap';
import ProjectStatusSelect from './ProjectStatusSelect';
import * as yup from 'yup';
import { Project } from '../store/projectCrud';
import dayjs from 'dayjs';
import _ from 'lodash';
export interface ProjectFormValues extends Omit<Project, 'status_type_id'> {
  status_type_id: string | number,
  id?: string;
};
export interface ProjectFormProps {
  onSubmit: (values: ProjectFormValues) => Promise<void> | void,
  initialValues?: ProjectFormValues,
  buttonLabel?: string,
}

const validationSchema = (y: typeof yup, isUpdate: boolean) => {
  const startSchema = y.date().required().label('Fecha de inicio');
  return y.object().shape({
    name: y.string().required().label('Nombre'),
    description: y.string().required().label('Descripción'),
    start: isUpdate ? startSchema : startSchema.min(dayjs().startOf('day').toISOString(), 'Debe ser mayor a la fecha actual'),
    end: y.date().required().min(dayjs().startOf('day').toISOString(), 'Debe ser mayor a la fecha actual').label('Fecha de finalización').min(y.ref('start'), 'Debe ser mayor a la fecha de inicio'),
    status_type_id: y.number().required().label('Estado'),
  })
};

const ProjectForm: React.FunctionComponent<ProjectFormProps> = (props: ProjectFormProps) => {
  const initialValues = React.useMemo(() => {
    const defaultValues = {
      name: '',
      description: '',
      start: '',
      end: '',
      status_type_id: ''
    };
    const initialValues = props.initialValues;

    if (!initialValues || !_.isObject(initialValues)) {
      return defaultValues;
    }

    return {
      ...initialValues,
      start: dayjs(initialValues.start).format('YYYY-MM-DD'),
      end: dayjs(initialValues.end).format('YYYY-MM-DD'),
    };
  }, [props.initialValues]);

  return (
    <Formik<ProjectFormValues>
      validationSchema={validationSchema(yup, 'id' in initialValues)}
      initialValues={initialValues}
      onSubmit={props.onSubmit}
    >
      {({ values, handleSubmit, isSubmitting, handleChange, handleBlur, touched, errors }) => (
        <Form>
          <Card.Body>
            <Row>
              <Form.Group as={Col} md="4" controlId="formBasicEmail">
                <Form.Label>Nombre</Form.Label>
                <Form.Control isInvalid={!!(touched.name && errors.name)} name="name" value={values.name} onChange={handleChange('name')} onBlur={handleBlur('name')}>
                </Form.Control>
                {touched.name && (
                  <Form.Text className="text-danger">{errors.name}</Form.Text>
                )}
                <Form.Text className="text-muted">
                  Ingrese el nombre
                </Form.Text>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="formBasicEmail">
                <Form.Label>Fecha Inicial</Form.Label>
                <Form.Control isInvalid={!!(touched.start && errors.start)} name="start" type="date" value={values.start} onChange={handleChange('start')} onBlur={handleBlur('start')}>
                </Form.Control>
                {touched.start && (
                  <Form.Text className="text-danger">{errors.start}</Form.Text>
                )}
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="formBasicEmail">
                <Form.Label>Fecha Final</Form.Label>
                <Form.Control isInvalid={!!(touched.end && errors.end)} name="end" type="date" value={values.end} onChange={handleChange('end')} onBlur={handleBlur('end')}>
                </Form.Control>
                {touched.end && (
                  <Form.Text className="text-danger">{errors.end}</Form.Text>
                )}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="8" controlId="formBasicEmail">
                <Form.Label>Descripción</Form.Label>
                <Form.Control
                  isInvalid={!!(touched.description && errors.description)}
                  name="description"
                  as="textarea"
                  rows={2}
                  value={values.description}
                  onChange={handleChange('description')}
                  onBlur={handleBlur('description')}
                >
                </Form.Control>
                {touched.description && (
                  <Form.Text className="text-danger">{errors.description}</Form.Text>
                )}
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="formBasicEmail">
                <Form.Label>Estado</Form.Label>
                <ProjectStatusSelect></ProjectStatusSelect>
                <Form.Text className="text-muted">
                  Ingrese el dato
                  </Form.Text>
              </Form.Group>
            </Row>
          </Card.Body>
          <Divider />
          <Card.Body className="text-right">
            <a className="btn btn-secondary mr-2 secondary" href="/proyectos" style={{
              minWidth: 120
            }}>Cancelar</a>
            <Button disabled={isSubmitting} onClick={() => handleSubmit()} style={{
              minWidth: 120
            }}>{isSubmitting ? 'Creando...' : (props.buttonLabel ?? 'Crear')}</Button>
          </Card.Body>
        </Form>
      )}
    </Formik>
  );
}

export default ProjectForm;