import * as React from 'react';
import { Badge, Button, Form, Row, Table } from 'react-bootstrap';
import SVG from "react-inlinesvg";
import PaginationPaginationSize from '../../Navigation/components/PaginationPaginationSize';
import PaginationTables from '../../Navigation/components/PaginationTables';


export interface OportunitiesTableProps {

}

const OportunitiesTable: React.SFC<OportunitiesTableProps> = (props: OportunitiesTableProps) => {
    return (
        <>
            <Row>
                <Table responsive className="text-center">
                    <thead>
                        <tr>
                            <th>
                                <Form.Check></Form.Check>
                            </th>
                            <th>ID</th>
                            <th>NOMBRE</th>
                            <th>DESCRIPCIÓN</th>
                            <th>FECHA INICIO</th>
                            <th>FECHA FIN</th>
                            <th>ESTADO</th>
                            <th>PDVs ASIGNADAS</th>
                            <th>ACCIÓN</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><Form.Check></Form.Check></td>
                            <td>1</td>
                            <td>Prueba</td>
                            <td>Lorem ipsum</td>
                            <td>01/07/2020</td>
                            <td>01/10/2020</td>
                            <td> <Badge variant="secondary" style={{
                                color: '#05C55C',
                                borderColor: '#05C55C',
                                backgroundColor: 'rgba(5,197,92,0.32)',
                            }}>Activo</Badge></td>
                            <td>1</td>
                            <td>
                                <div className="d-flex align-items-center justify-content-center">
                                    <Button className="ml-2" variant="success" size="sm">
                                        <SVG src="/media/svg/icons/mira.svg"></SVG>
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Row>
            <div className="d-flex justify-content-between align-items-center">
                <PaginationTables></PaginationTables>
                <PaginationPaginationSize></PaginationPaginationSize>
            </div>
        </>
    );
}

export default OportunitiesTable;